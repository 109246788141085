import React from 'react';
import PropTypes from 'prop-types';
import Item from 'antd/lib/form/FormItem'
import Checkbox from '~/components/Checkbox/Checkbox';
import withLocate from '~/hocs/withLocate';


const {object, func} = PropTypes;


CheckboxReceiveNews.propTypes = {
    form: object.isRequired,
    text: func
};


function CheckboxReceiveNews(props) {
    const {t, text, form: {getFieldDecorator}} = props;

    return (
        <div className="CheckboxReceiveNews">
            <Item>
                {getFieldDecorator('promo_notify_agree', {})(
                    <Checkbox>{t('auth: promo_agree')}</Checkbox>
                )}
            </Item>
        </div>
    );
}


export default withLocate(CheckboxReceiveNews);
