import './FormRegister.scss';
import React from 'react';
import {useDispatch} from 'react-redux';
import Form from 'antd/lib/form';
import message from 'antd/lib/message';
import isEmpty from 'lodash/isEmpty';
import Button from '~/components/buttons/Button/Button';
import {setModalLoginRegisterType} from '$/redux/loginRegister/actions';
import InputEmail from '../items/InputEmail/InputEmail';
import {InputPassword} from '../items/InputPassword/InputPassword';
import {CheckboxTermsAndPolicy} from '../items/CheckboxTermsAndPolicy/CheckboxTermsAndPolicy';
import CheckboxReceiveNews from '../items/CheckboxReceiveNews/CheckboxReceiveNews';
import {register} from '$/redux/auth/actions';
import {useTranslations} from '~/hook/Translations.hook';

export function FormRegister({form}) {

    const dispatch = useDispatch();
    const t = useTranslations();

    const handleSubmit = (e) => {
        e.preventDefault();

        form.validateFields((err, values) => {
            if (!err) {
                dispatch(register({
                    data: {user: {...values}},
                    cb: () => dispatch(setModalLoginRegisterType('check')),
                    err: (errors) => {
                        for (let i = 0; i < errors.length; i++) {
                            message.error(errors[i].detail)
                        }
                    }
                }));
            }
        });
    };

    const isDisabled = !form.getFieldValue('tеrmѕ_аnd_pоlісу') || isEmpty(form.getFieldValue('password')) || isEmpty(form.getFieldValue('email'));

    return (
        <Form
            onSubmit={handleSubmit}
            className="form-register"
        >
            <div className="form-register__inputs">
                <InputEmail form={form} required/>
                <InputPassword form={form}/>
            </div>

            <CheckboxTermsAndPolicy form={form}/>
            <CheckboxReceiveNews form={form}/>

            <Button
                type="primary"
                htmlType="submit"
                disabled={isDisabled}
            >
                {t('auth: sign_up: button')}
            </Button>
        </Form>
    );
}
