const routes = require('next-routes')();

function getRoutes() {
    return [
        {
            name: 'index',
            page: 'index',
            pattern: '/'
        },
        {
            name: 'index-locale',
            page: 'index',
            pattern: '/:locale'
        },
        {
            page: 'search',
            pattern:
                '/:locale/find/:slug1?/:slug2?/:slug3?/:slug4?/:slug5?/:slug6?/:slug7?/:slug8?/:slug9?/:slug10?/:slug11?/:slug12?/:slug13?/:slug14?/:slug15?/:slug16?/:slug17?/:slug18?/:slug19?'
        },
        {
            name: 'gallery',
            page: 'gallery',
            pattern: '/:locale/gallery/:slug1?/:slug2?/:slug3?/:slug4?/:slug5?/:slug6?/:slug7?/:slug8?/:slug9?'
        },
        {
            name: 'project',
            page: 'project',
            pattern: '/:locale/project/:slug'
        },
        {
            name: 'business',
            page: 'business',
            pattern: '/:locale/:marker/:city/:slug'
        }
    ];
}

const generateRoutes = routesObject => {
    getRoutes()
        .forEach(page => {
            routesObject.add({
                name: page.name,
                pattern: page.pattern,
                page: page.page
            });
        });
    return routesObject;
};

module.exports = generateRoutes(routes);
