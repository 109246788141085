import './FormLogin.scss';
import React from 'react';
import Form from 'antd/lib/form';
import message from 'antd/lib/message';
import {useDispatch} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Button from '~/components/buttons/Button/Button';
import InputEmail from '../items/InputEmail/InputEmail';
import {InputPassword} from '../items/InputPassword/InputPassword';
import {setModalLoginRegisterVisible} from '$/redux/loginRegister/actions';
import {login} from '$/redux/auth/actions';
import {useTranslations} from '~/hook/Translations.hook';

export function FormLogin({form}) {

    const t = useTranslations();
    const dispatch = useDispatch();

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                dispatch(login({
                    data: {user: {...values}},
                    cb: () => {
                        message.success(t('auth: sign_in: success'));
                        dispatch(setModalLoginRegisterVisible(false));
                    },
                    err: errors => {
                        for (let i = 0; i < errors.length; i++) {
                            message.error(errors[i].detail);
                        }
                    }
                }));
            }
        });
    };

    const handleDisabled = () => {
        return isEmpty(form.getFieldValue('password')) || isEmpty(form.getFieldValue('email'));
    };

    return (
        <Form onSubmit={handleSubmit} className="form-login">
            <InputEmail form={form} required/>
            <InputPassword form={form}/>

            <Button type="primary" htmlType="submit" disabled={handleDisabled()}>
                {t('auth: sign_in: button')}
            </Button>
        </Form>
    );
}
