import './Modal.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import AntModal from 'antd/lib/modal';
import {classes} from "~/helpers/classes";
import Icon from "~/components/Icon/Icon";

function Modal (
    {
        children,
        className,
        visible,
        onCancel,
        closeIcon,
        ...otherProps
    }
) {
    const classNames = classes('modal', className);

    return (
        <AntModal
            className={classNames}
            closable={false}
            destroyOnClose
            visible={visible}
            onCancel={() => onCancel && onCancel()}
            footer={null}
            width="auto"
            centered
            {...otherProps}
        >
            { closeIcon ? (
                <div className="modal__close" onClick={() => onCancel && onCancel()}>
                    {closeIcon}
                </div>
            ) : (
                <div className="modal__close" onClick={() => onCancel && onCancel()}>
                    <Icon type="close"/>
                </div>
            )}
            <div className="modal__inner">
                {children}
            </div>
        </AntModal>
    )
}

Modal.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    otherProps: PropTypes.object
};

export default Modal;
