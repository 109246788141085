import * as React from 'react';
import {useSelector} from 'react-redux';

export const useTranslations = () => {
    const {language, translations} = useSelector(store => ({
        language: store.locate.language,
        translations: store.locate.translations
    }));
    const [translationData, setTranslationData] = React.useState(translations[(language === 'ua') ? 'uk' : language]);

    React.useEffect(() => {
        if (translations && language) {
            const lang = (language === 'ua') ? 'uk' : language;
            setTranslationData(translations[lang]);
        }
    }, [language, translations]);

    const regExpCreator = (option) => new RegExp(`%{${option}}`);

    return (query, options) => {
        if (!translationData) return query;

        const phrase = translationData[query];
        if (phrase && options) {
            const arrayOfPhase = phrase.split(' ')
            .map(it => `${it} `);

            arrayOfPhase.forEach((item, index) => {
                Object.keys(options)
                .forEach((optionKey) => {
                    const arrayOfPhaseSplitting = item.split(regExpCreator(optionKey));
                    if (arrayOfPhaseSplitting.length > 1) {
                        arrayOfPhase[index] = <React.Fragment key={index}>
                            {`${arrayOfPhaseSplitting[0]}`}
                            {options[optionKey]}
                            {`${arrayOfPhaseSplitting[1]}`}
                        </React.Fragment>
                    }
                })
            });
            return arrayOfPhase;
        }

        return phrase || query;
    };
};
