import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input} from 'antd';
//import Input from '~/components/Input/Input';
import {useTranslations} from '~/hook/Translations.hook';

function InputEmail({form: {getFieldDecorator}, required, initialValue}) {
    const t = useTranslations();

    return (
        <div className="input-email">
            <Form.Item>
                {getFieldDecorator('email', {
                    rules: [
                        {
                            required: !!required,
                            message: t('form: error: required')
                        },
                        {
                            type: 'email',
                            message: t('form: error: email')
                        }
                    ],
                    initialValue: initialValue
                })(
                    <Input placeholder={t('auth: fields: email')}/>
                )}
            </Form.Item>
        </div>
    );
}

InputEmail.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired
    }).isRequired,
    required: PropTypes.bool,
    initialValue: PropTypes.string
};

InputEmail.defaultProps = {
    required: false,
    initialValue: null
}

export default InputEmail;
