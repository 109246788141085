import React from 'react';

const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GTAG_TRACKING_ID;

export const GtagScript = () => {
    if (!GA_TRACKING_ID) return false;

    return (
        <script
            dangerouslySetInnerHTML={{
                __html: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer',\'${GA_TRACKING_ID}\');
      `
            }}
        />
    )
};

export const GtagNoscript = () => {
    if (!GA_TRACKING_ID) return false;

    return (
        <noscript>
            <iframe
                src={`https://www.googletagmanager.com/ns.html?id=${GA_TRACKING_ID}`}
                height="0"
                width="0"
                style={{
                    display: 'none',
                    visibility: 'hidden'
                }}
            />
        </noscript>
    )
};
