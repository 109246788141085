import {connect} from 'react-redux';
import {locateHOC} from './locateHOC'

const withLocate = WrappedComponent => {
    const HOC = locateHOC(WrappedComponent)

    const mapStateToProps = state => ({
        language: state.locate.language,
        country: state.locate.country,
        countryCode: state.locate.countryCode,

        languages: state.locate.languages,
        countries: state.locate.countries,
        translations: state.locate.translations,
        localeTranslations: state.locate.localeTranslations
    });

    return connect(mapStateToProps)(HOC);
};

export default withLocate;
