import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {ALL_PREFIX, SEARCH_BY_MAP, TYPE} from '../../constants/searchParameters';
import {COUNTRY, LOCALITY, NEIGHBORHOOD, POLITICIAN, STATE_AREA, STATE_AREA_BY_GEOCODE} from '../../constants/geotypes';

export const parseUrlToArray = (slugs, separator) => Object.values(slugs)
    .filter(
        slug => slug !== separator
    )
    .map(
        slug => {
          const [prefix, ...value] = slug.split('-');

          if (!ALL_PREFIX.includes(`${prefix}-`)) {
            return {
              prefix: TYPE,
              value: `${prefix}${!isEmpty(value) ? '-' + value.join('-') : ''}`
            };
          }

          return {
            prefix: prefix === SEARCH_BY_MAP ? prefix : `${prefix}-`,
            value: prefix === SEARCH_BY_MAP ? '.' : value.join('-')
          };
        }
    );

export const getParamByPrefix = (params, _prefix) => {
  const param = params.find(({prefix}) => prefix === _prefix);
  return get(param, 'value', null);
};

export const isIncludedType = (type, types) => types.includes(type) && types.includes(POLITICIAN);

export const parseGeotypes = (types) => {
  const arr = [COUNTRY, STATE_AREA_BY_GEOCODE, LOCALITY, NEIGHBORHOOD]
      .filter((type) => isIncludedType(type, types));

  if (arr.length) {

    if (arr[0] === STATE_AREA_BY_GEOCODE) {
      return STATE_AREA;
    }

    return arr[0];
  }

  return '';
};

const getCityAddressComponent = (type, addresses) => addresses.find(address => {
  const addressTypes = get(address, 'types', [])
      .reverse();
  return addressTypes.includes(type) && addressTypes.includes(POLITICIAN);
});

export const getCityFromAddress = (type, addresses = []) => {

  if ([LOCALITY, NEIGHBORHOOD].includes(type) && !isEmpty(addresses)) {

    const cityAddress = getCityAddressComponent(type, addresses);

    if (cityAddress && cityAddress.hasOwnProperty('long_name')) {
      return cityAddress.long_name;
    }

    const _addresses = get(cityAddress, 'address_components', []);
    const _cityAddress = getCityAddressComponent(type, _addresses);

    return get(_cityAddress, 'long_name', '');
  }

  return '';
};
