import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ModalCheckYourEmail} from './components/ModalCheckYourEmail/ModalCheckYourEmail';
import Modal from '../Modal/Modal';
import {setModalLoginRegisterVisible} from '$/redux/loginRegister/actions';
import Login from './components/ModalLogin/ModalLogin';
import Register from './components/ModalRegister/ModalRegister';
import {ModalForgotYourPassword} from './components/ModalForgotYourPassword/ModalForgotYourPassword';

const VIEWS = {
    login: <Login/>,
    register: <Register/>,
    check: <ModalCheckYourEmail/>,
    forgot: <ModalForgotYourPassword/>
};

export function ModalLoginRegister() {

    const {visible, type} = useSelector(state => ({
        visible: state.loginRegister.visible,
        type: state.loginRegister.type
    }));
    const dispatch = useDispatch();

    return (
        <Modal
            visible={visible}
            onCancel={() => dispatch(setModalLoginRegisterVisible(false))}
        >
            {VIEWS[type]}
        </Modal>
    );
}