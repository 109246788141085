import './AccountUser.scss';
import React from 'react';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import {useSelector} from 'react-redux';
import Logout from '../Logout/Logout';
import isEmpty from 'lodash/isEmpty';
import userAvatar from '#/user.png';
import {useLocale} from '~/hook/Locale.hook';
import {useTranslations} from '~/hook/Translations.hook';

const {Item} = Menu;

export function AccountUser() {

    const {locatesUrl} = useLocale();
    const t = useTranslations();
    const {email, firstName, ava, role, helpLinks} = useSelector(state => ({
        email: state.auth.user.email,
        firstName: state.auth.user.first_name,
        ava: state.auth.user.avatar_image_url,
        role: state.auth.user.role,
        helpLinks: state.auth.helpLinks
    }));
    const userMenu = [
        {
            id: 1,
            title: t('header: signed_in_navbar: edit_profile'),
            link: '/profile#profile'
        }
    ];

    const menu = (
        <Menu>
            {userMenu.map(item => (
                <Item key={item.id}>
                    <a href={item.link}>
                        {item.title}
                    </a>
                </Item>
            ))}

            {role === 'operator' && (
                <Item key="4">
                    <a href="/station_admin">
                        {t('header: signed_in_navbar: operator_cabinet')}
                    </a>
                </Item>
            )}

            {role === 'bookkeeper' && (
                <Item key="4">
                    <a href="/station_admin">
                        {t('header: signed_in_navbar: bookkeeper_cabinet')}
                    </a>
                </Item>
            )}

            {role === 'night_operator' && (
                <Item key="4">
                    <a href="/station_admin">
                        {t('header: signed_in_navbar: night_operator_cabinet')}
                    </a>
                </Item>
            )}

            {role === 'moderator' && (
                <Item key="4">
                    <a href="/station_admin">
                        {` ${t('header: signed_in_navbar: moderator_cabinet')}`}
                    </a>
                </Item>
            )}

            {role === 'station_manager' && (
                <Item key="4">
                    <a href="/station_admin">
                        {t('header: signed_in_navbar: manager_cabinet')}
                    </a>
                </Item>
            )}

            {role === 'station_admin' && (
                <Item key="4">
                    <a href="/station_admin">{t('header: signed_in_navbar: station_admin')}</a>
                </Item>
            )}

            {role === 'serviceman' && (
                <Item key="4">
                    <a href="/station_admin">{t('header: signed_in_navbar: serviceman_cabinet')}</a>
                </Item>
            )}

            {!isEmpty(helpLinks)
            && helpLinks.map(
                (item, index) => !isEmpty(item) && (
                    <Item key={item.slug}>
                        <a href={`${locatesUrl}/${item.slug}`}>{item.title}</a>
                    </Item>
                )
            )}

            <Item key="0">
                <Logout/>
            </Item>
        </Menu>
    );

    return (
        <div className="account-user">
            <Dropdown overlay={menu} trigger={['click']} overlayClassName="account-user__menu">
                <div className="account-user__menu-content">
                    <div className="account-user__username" title={firstName || email}>
                        {firstName || email}
                    </div>

                    <div className="account-user__avatar">
                        <img src={ava || userAvatar} alt={firstName || email}/>
                    </div>
                </div>
            </Dropdown>
        </div>
    );
}
