import './MasterSelect.scss';
import * as React from 'react';
import {ajax} from '$/api/ajax';
import {SEARCH_MASTERS_BY_STATION} from '$/api/routes';
import {useLocale} from '~/hook/Locale.hook';
import {useTranslations} from '~/hook/Translations.hook';
import Form from 'antd/lib/form';
import {Select} from 'antd';
import debounce from 'lodash/debounce'
import Spinner from '~/components/Spinner/Spinner';

const {Option} = Select;

export function MasterSelect({form: {getFieldDecorator, getFieldValue}, initialValue, placeholder, businessId}) {

    const {language} = useLocale();
    const t = useTranslations();
    const [isLoading, setIsLoading] = React.useState(false);
    const [masters, setMasters] = React.useState([]);
    const selectedServices = getFieldValue('service');

    React.useEffect(() => {
        fetchMasters();
    }, [language, selectedServices]);

    const fetchMasters = async (text = '') => {
        setIsLoading(true);
        const servicesIds = (selectedServices || []).map(item => `&service_ids[]=${item.value}`).join('');

        const data = await new Promise(((resolve, reject) => {
            ajax({
                url: `${SEARCH_MASTERS_BY_STATION}?station_id=${businessId}${text ? '&term=' + text : ''}${servicesIds ? servicesIds : ''}`,
                cb: res => {
                    resolve(res);
                },
                err: error => {
                    reject(error);
                },
                language: language === 'uk' ? 'UA' : language.toUpperCase()
            })
        }));

        setMasters(data);
        setIsLoading(false);
    };

    return (
        masters.length ? (
            <div className="master-select">
                <Form.Item>
                    {getFieldDecorator('master', {
                        initialValue: (initialValue ? String(initialValue) : masters.length === 1 ? masters[0].id : undefined)
                    })(
                        <Select
                            className="master-select__select"
                            placeholder={placeholder}
                            onSearch={debounce(fetchMasters, 800)}
                            onFocus={fetchMasters}
                            notFoundContent={isLoading
                                ? <Spinner size="small" color={'rose'}/>
                                : <div>{t('select: not_found')}</div>
                            }
                            optionFilterProp="children"
                            showSearch
                            allowClear
                        >
                            {masters.map((item) => {
                                    return <Option
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.label}
                                    </Option>
                                }
                            )}
                        </Select>
                    )}
                </Form.Item>
            </div>
        ) :
        null
    )
}
