import './FormOrder.scss';
import React from 'react';
import {Col, Form, message, Row} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {useTranslations} from '~/hook/Translations.hook';
import InputEmail from '~/components/form/items/InputEmail/InputEmail';
import InputName from '~/components/form/items/InputName/InputName';
import ItemInputPhone from '~/components/form/items/InputPhone/InputPhone';
import {ServiceSelect} from '~/components/form/items/ServiceSelect/ServiceSelect';
import {BrandSelect} from '~/components/form/items/BrandSelect/BrandSelect';
import {MasterSelect} from '~/components/form/items/MasterSelect/MasterSelect';
import {DateTimePicker} from '~/components/form/items/DateTimePicker/DateTimePicker';
import {CommentTextarea} from '~/components/form/items/CommentTextarea/CommentTextarea';
import {CheckboxTermsAndPolicy} from '~/components/form/items/CheckboxTermsAndPolicy/CheckboxTermsAndPolicy';
import Button from '~/components/buttons/Button/Button';
import {ajax} from '$/api/ajax';
import {URL_SEND_ORDER} from '$/api/routes';
import {CallbackSelect} from '~/components/form/items/CallbackSelect/CallbackSelect';
import moment from 'moment';
import {closeModal} from '$/redux/order/actions';
import {setModalLoginRegisterType, setModalLoginRegisterVisible} from '$/redux/loginRegister/actions';
import Spinner from '~/components/Spinner/Spinner';
import {useLocale} from '~/hook/Locale.hook';
import {OrderStatus} from '~/components/OrderStatus/OrderStatus';
import Icon from '~/components/Icon/Icon';
import {cancelApplication} from '$/redux/station/actions';
import {useIsInIframe} from '~/hook/IsInIframe.hook';

function Order({
                   form,
                   businessId,
                   serviceId,
                   masterId,
                   settings
               }) {

    const inIFrame = useIsInIframe();
    const t = useTranslations();
    const dispatch = useDispatch();
    const {language} = useLocale();
    const [sendOrder, setSendOrder] = React.useState(null);
    const [isSendOrder, setIsSendOrder] = React.useState(false);
    const [isServiceLoad, setIsServiceLoad] = React.useState(false);
    const {
        authorized,
        user,
        searchFields
    } = useSelector(state => ({
        settings: state.countryInfo.info.order_settings,
        callMeSettings: state.countryInfo.info.call_me_setting,
        authorized: state.auth.authorized,
        user: state.auth.user,
        searchFields: state.searchFields.selected
    }))
    const {
        orderSettings,
        callMeSettings,
        callMeActive,
        brandRequired,
        cancellationPolicy
    } = {
        orderSettings: settings.order_settings,
        callMeSettings: settings.call_me_setting,
        callMeActive: settings.call_me_active,
        brandRequired: settings.brand_required,
        cancellationPolicy: settings.cancellation_policy
    }
    const {
        selectedServices,
        selectedBrand,
        selectedDate,
        selectedTime,
        selectedMaster
    } = {
        selectedServices: searchFields.main.map(it => parseInt(it.id)),
        selectedBrand: searchFields.brand.id,
        selectedDate: searchFields.date,
        selectedTime: searchFields.time,
        selectedMaster: searchFields.master
    }

    const onSubmitClick = () => {
        form.validateFields(async (err, values) => {
            if (err) {
                message.error(t('order_form: validation: error'));
                console.log('Wrong field value', err, values);
            } else {
                setIsSendOrder(true);
                const order = {
                    station_id: businessId,
                    email: values.email,
                    phone: `${values.phone}`,
                    name: values['first-name'],
                    call_me: values['callback'],
                    brand_id: values.brand,
                    date: values.date?.format('YYYY-MM-DD'),
                    time: values.time?.format('HH:mm'),
                    service_ids: values.service?.map(it => it.value),
                    extra: values.comment,
                    slot_id: parseInt(values.master, 10)
                };

                ajax({
                    url: URL_SEND_ORDER,
                    method: 'POST',
                    data: {order},
                    cb: (res) => {
                        if (res?.meta?.liqpay_request?.href) {
                            window.location.href = res.meta.liqpay_request.href;
                        } else if (res?.meta?.liqpay_request?.message) {
                            message.error(t(`${res.meta.liqpay_request.message}`));
                        } else {
                            setSendOrder(res?.data);
                            setIsSendOrder(false);
                        }
                    },
                    err: (errors) => {
                        for (let i = 0; i < errors.length; i++) {
                            message.error(errors[i].detail);
                        }
                    },
                    language: language === 'uk' ? 'UA' : language.toUpperCase()
                })
            }
        })
    };

    if (isSendOrder) {
        return (
            <div className="form-order">
                <Spinner color={'rose'}/>
            </div>
        )
    }

    if (sendOrder) {
        return <OrderInfo {...sendOrder}/>
    }

    return (
        <div className="form-order">
            <div className="form-order__header">
                {t('order_card: header: new_order')}
            </div>
            {!inIFrame && !authorized && (
                <div className="form-order__description">
                    {t('order_form: description: login_or_register', {
                        login: <Button
                            type="line-link"
                            onClick={() => {
                                dispatch(setModalLoginRegisterType('login'))
                                dispatch(setModalLoginRegisterVisible(true))
                            }}
                        >
                            {t('order_form: description: login')}
                        </Button>,
                        register: <Button
                            type="line-link"
                            onClick={() => {
                                dispatch(setModalLoginRegisterType('register'))
                                dispatch(setModalLoginRegisterVisible(true))
                            }}
                        >
                            {t('order_form: description: register')}
                        </Button>
                    })}
                </div>
            )}
            <div className="form-order__item">
                <InputName
                    form={form}
                    initialValue={user?.first_name}
                    required
                />
            </div>
            {orderSettings?.quick_order_email_field_enable && (
                <div className="form-order__item">
                    <InputEmail
                        form={form}
                        initialValue={user?.email}
                        required={orderSettings?.email_required}
                    />
                </div>
            )}
            {orderSettings?.quick_order_phone_field_enable && (
                <div className="form-order__item">
                    <ItemInputPhone
                        form={form}
                        initialValue={user?.phone}
                        required={orderSettings?.phone_required}
                    />
                </div>
            )}
            <div className="form-order__item">
                <MasterSelect
                    form={form}
                    required
                    businessId={businessId}
                    placeholder={t('order_form: fields: masters: placeholder')}
                    initialValue={masterId || selectedMaster.id}
                />
            </div>
            <div className="form-order__item">
                <ServiceSelect
                    form={form}
                    required={orderSettings?.service_required}
                    placeholder={t('order_form: fields: services: placeholder')}
                    businessId={businessId}
                    initialValue={serviceId ? [parseInt(serviceId)] : selectedServices}
                    onLoad={() => setIsServiceLoad(true)}
                />
            </div>
            <div className="form-order__item">
                <BrandSelect
                    form={form}
                    placeholder={t('order_form: fields: brand: placeholder')}
                    businessId={businessId}
                    initialValue={selectedBrand}
                    required={brandRequired}
                />
            </div>
            <div className="form-order__item">
                <DateTimePicker
                    form={form}
                    placeholder={t('order_form: fields: calendar: placeholder')}
                    businessId={businessId}
                    dateInitialValue={selectedDate && moment(selectedDate)}
                    timeInitialValue={selectedTime && moment(selectedTime, 'HH:mm')}
                    required={orderSettings?.date_required}
                    serviceIds={form.getFieldValue('service')
                    ?.map(it => it.value)}
                    disabled={!form.getFieldValue('service')?.length && !isServiceLoad}
                />
            </div>
            {orderSettings?.quick_order_extra_field_enable && (
                <div className="form-order__item">
                    <CommentTextarea
                        form={form}
                        placeholder={t('station_card: form: extra: placeholder')}
                    />
                </div>
            )}
            {callMeSettings?.call_me_mode && callMeActive && (
                <div className="form-order__item">
                    <CallbackSelect
                        form={form}
                        initialValue={callMeSettings?.default_call_me_mode}
                    />
                </div>
            )}
            {!authorized && (
                <div className="form-order__item">
                    <CheckboxTermsAndPolicy
                        form={form}
                    />
                </div>
            )}
            <PrepaidServicesText form={form} cancellationPolicy={cancellationPolicy}/>
            <div className="form-order__item">
                <Form.Item>
                    <Row type="flex" justify="center" gutter={20}>
                        <Col xs={12} md={12} className="form-order__button-col">
                            <Button
                                type="primary"
                                onClick={onSubmitClick}
                            >
                                {t('order_form: buttons: submit')}
                            </Button>
                        </Col>
                        <Col xs={12} md={12} className="form-order__button-col">
                            <Button
                                onClick={() => dispatch(closeModal())}
                            >
                                {t('order_form: buttons: cancel')}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </div>
            <div className="form-order__item">
                <TotalPriceText form={form}/>
            </div>
        </div>
    )
}

const PrepaidServicesText = ({
                                 form,
                                 cancellationPolicy
                             }) => {
    const t = useTranslations();
    const {currency} = useLocale();
    const [price, setPrice] = React.useState('');

    React.useEffect(() => {
        setTimeout(() => {
            const prepaidServices = form.getFieldValue('service')
            ?.filter(s => s.prepaid);
            const prepaidPrice = prepaidServices?.reduce((sum, service) => {
                return sum + service.price_from
            }, 0);
            setPrice(prepaidPrice)
        }, 500);
    })

    return <div className="form-order__item">
        {
            !!price && cancellationPolicy?.cancellation_policy_fully_free && (
                <div className="form-order__small-text">
                    {t('order_form: cancellation_policy: fully_free')}
                </div>
            )
        }
        {
            !!price && cancellationPolicy?.cancellation_policy_fully_free === false && (
                <div>
                    <div className="form-order__small-text">
                        <div>
                            {t('order_form: cancellation_policy: free_days', {days: cancellationPolicy?.cancellation_policy_free_days})}
                        </div>
                        <div>
                            {t('order_form: cancellation_policy: refundable_part', {
                                days: cancellationPolicy?.cancellation_policy_free_days,
                                part_in_percent: cancellationPolicy?.cancellation_policy_refundable_part
                            })}
                        </div>
                    </div>
                </div>
            )
        }
        <div className="form-order__small-text">
            {!!price ? t('order_form: prepaid_amount', {amount: `${price} ${currency.symbol}`}) : ''}
        </div>
    </div>
}

const TotalPriceText = ({form}) => {
    const t = useTranslations();
    const {currency} = useLocale();
    const [price, setPrice] = React.useState('');

    React.useEffect(() => {
        setTimeout(() => {
            const totalPrice = form.getFieldValue('service')
            ?.reduce((sum, service) => {
                return sum + service.price_from
            }, 0)
            setPrice(totalPrice)
        }, 500);
    })

    return (
        <div className="form-order__calc-price">
            {!!price ? t('order_form: total_amount') + ` ${price} ${currency.symbol}` : ''}
        </div>
    )
}

function OrderInfo({
                       id,
                       status,
                       status_i18n,
                       name,
                       phone,
                       email
                   }) {

    const t = useTranslations();
    const inIFrame = useIsInIframe();
    const {authorized} = useSelector(state => ({
        authorized: state.auth.authorized
    }))
    const dispatch = useDispatch();

    return (
        <div className="order-info">
            <div className="order-info__header">
                <div className="order-info__header-text">
                    {t('order_info: number', {number: `№${id}`})}
                </div>
                <div className="order-info__header-status">
                    <OrderStatus
                        type={status}
                        text={status_i18n}
                    />
                </div>
            </div>
            {!inIFrame && !authorized && (
                <div className="order-info__description">
                    {t('order_form: description: login_or_register', {
                        login: <Button
                            type="line-link"
                            onClick={() => {
                                dispatch(setModalLoginRegisterType('login'))
                                dispatch(setModalLoginRegisterVisible(true))
                            }}
                        >
                            {t('order_form: description: login')}
                        </Button>,
                        register: <Button
                            type="line-link"
                            onClick={() => {
                                dispatch(setModalLoginRegisterType('register'))
                                dispatch(setModalLoginRegisterVisible(true))
                            }}
                        >
                            {t('order_form: description: register')}
                        </Button>
                    })}
                </div>
            )}
            {!isEmpty(name) && (
                <div className="order-info__item">
                    {t('order_info: fields: name', {name})}
                </div>
            )}
            {!isEmpty(email) && (
                <div className="order-info__item">
                    {t('order_info: fields: email', {email})}
                </div>
            )}
            {!isEmpty(phone) && (
                <div className="order-info__item">
                    {t('order_info: fields: phone', {phone})}
                </div>
            )}
            <div className="order-info__call-block">
                <Icon type="phone"/>
                {t('order_info: call_message')}
            </div>
            <div className="order-info__button">
                <Button
                    type="dot-link"
                    onClick={() => {
                        dispatch(cancelApplication(id))
                        dispatch(closeModal())
                    }}>
                    {t('order_info: buttons: cancel')}
                </Button>
            </div>
        </div>
    );
}

export const FormOrder = Form.create()(Order);
