import {
    ADDITIONAL_SERVICES,
    BODY_TYPES,
    BRAND,
    CITY,
    DATE,
    DISTANCE,
    GEO_TYPE,
    LATITUDE,
    LONGITUDE,
    NETWORK,
    NORM_HOUR,
    NORTH_EAST_LAT,
    NORTH_EAST_LNG,
    PAGE_NUMBER,
    PROBLEM,
    SEARCH_BY_MAP,
    SERVICE,
    SORT_BY,
    SOUTH_WEST_LAT,
    SOUTH_WEST_LNG,
    TIME,
    TYPE
} from '$/constants/searchParameters';

export const SEARCH_PAGE_SEPARATOR = 'abs';

export const SEARCH_PAGE = {
    template: [
        TYPE, CITY, SERVICE, BRAND, SEARCH_PAGE_SEPARATOR, DISTANCE,
        DATE, TIME, ADDITIONAL_SERVICES, BODY_TYPES, NETWORK, NORM_HOUR,
        SORT_BY, SEARCH_BY_MAP, LATITUDE, LONGITUDE, GEO_TYPE,
        NORTH_EAST_LAT, NORTH_EAST_LNG, SOUTH_WEST_LAT, SOUTH_WEST_LNG,
        PAGE_NUMBER, PROBLEM
    ],
    separator: SEARCH_PAGE_SEPARATOR
};
