import './BurgerIcon.scss'
import React from "react";
import {classes} from "~/helpers/classes";

export const BurgerIcon = ({isActive, onClick}) => (
    <section
        className={classes('burger-icon', ['burger-icon_active', isActive])}
        onClick={() => onClick && onClick()}
    >
        <span className='burger-icon__item'/>
        <span className='burger-icon__item'/>
        <span className='burger-icon__item'/>
    </section>
);
