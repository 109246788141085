import './CheckboxTermsAndPolicy.scss';
import React from 'react';
import Item from 'antd/lib/form/FormItem';
import {useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Checkbox from '~/components/Checkbox/Checkbox';
import {useLocale} from '~/hook/Locale.hook';
import {useTranslations} from '~/hook/Translations.hook';

export function CheckboxTermsAndPolicy({form}) {

    const {termsLinks} = useSelector(state => ({
        termsLinks: state.countryInfo.info
    }));
    const t = useTranslations();

    const {locatesUrl} = useLocale();

    return (
        <div className="checkbox-terms-and-policy">
            <Item>
                {form.getFieldDecorator('tеrmѕ_аnd_pоlісу', {
                    rules: [{
                        validator: async (rule, value, callback) => {
                            return !value ? callback(t('form: error: required')) : callback();
                        }
                    }],
                    initialValue: false
                })(
                    <Checkbox className="checkbox-terms-and-policy__checkbox">
                        {t('auth: terms_agree_html', {
                            terms: !isEmpty(termsLinks) && (
                                <a href={`/${locatesUrl}/${termsLinks.terms_and_conditions.slug}`}>
                                    <a>
                                        <span>{termsLinks.terms_and_conditions.title}</span>
                                    </a>
                                </a>
                            ),
                            privacy: !isEmpty(termsLinks) && (
                                <a
                                    href={`${process.env.NEXT_PUBLIC_API_URL}/${locatesUrl}/${
                                        termsLinks.terms_and_conditions_for_station.slug
                                    }`}
                                >
                                    <a>
                                        <span>{termsLinks.terms_and_conditions_for_station.title}</span>
                                    </a>
                                </a>
                            )
                        })}
                    </Checkbox>
                )}
            </Item>
        </div>
    );
}
