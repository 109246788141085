import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import NextHead from 'next/head';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {GtagScript} from '~/helpers/gtag';
import SearchHead from './SearchHead';

const logo = `${process.env}/static/images/logo.png`;

const getPage = ({
                     url,
                     language,
                     country
                 }) => {
    const locate = `${language === 'uk' ? 'ua' : language}-${country}`;
    if (url) {
        if (url === `/${locate}` || url === `/${locate}/`) {
            return 'index';
        }
        if (url === `/${locate}/b2b` || url === `/${locate}/b2b/`) {
            return 'b2b';
        }
        if (url.startsWith(`/${locate}/find/`)) {
            return 'search';
        }
        if (url === (`/${locate}/gallery`) || url === (`/${locate}/gallery/`)) {
            return 'gallery';
        }
        if (url.startsWith(`/${locate}/project/`)) {
            return 'project';
        }
        return 'business';
    }
    return undefined;
};

const getSeoTags = ({
                        page,
                        seo: {title, metaTitle, metaDescription, metaKeywords, og} = {}
                    }) => {

    return <>
        {title && <title>{title}</title>}
        {metaTitle && <meta name="title" content={metaTitle}/>}
        {metaDescription && <meta name="description" content={metaDescription}/>}
        {metaKeywords && <meta name="keywords" content={metaKeywords}/>}
        {og && (
            <>
                {og.title && <meta property="og:title" content={page === 'search' ? metaTitle : og.title}/>}
                {og.description &&
                <meta property="og:description" content={page === 'search' ? metaDescription : og.description}/>}
                {process.browser && <meta property="og:url" content={window.location.href}/>}
                <meta property="og:image" content={!isEmpty(og.image) ? og.image : logo}/>
                <meta property="og:image:width" content="260"/>
                <meta property="og:image:height" content="315"/>
                <meta
                    property="og:site_name"
                    content="Nicy"
                />
                <meta property="og:type" content="website"/>
                <meta property="og:locale:alternate"/>
            </>
        )}
    </>;
};

const getMetaRobots = ({robotsContent, language, page}) => {
    if (language === 'en' || page === 'gallery' || page === 'project') {
        return <meta name="robots" content="noindex, nofollow"/>;
    }
    if (robotsContent && !isEmpty(robotsContent)) {
        return <meta name="robots" content={robotsContent}/>;
    }
    return <></>;
};

const Head = ({
                  url,
                  language,
                  country,
                  seo,
                  robotsContent,
                  robotsParamsContent,
                  filters = []
              }) => {
    const [isMount, setMounted] = useState(false);
    const locate = `${language === 'uk' ? 'ua' : language}-${country}`;
    const page = getPage({
        url,
        language,
        country
    });
    const markers = (filters.find(({id}) => id === 'markers') || {}).list || [];

    useEffect(() => {
        setMounted(true);
    }, [isMount]);

    return (
        <NextHead>
            <meta charSet="UTF-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>

            {/* ~~~~~~~~ GTM ~~~~~~~~ */}
            {<GtagScript/>}

            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.2/jquery.min.js"/>

            {/* ~~~~~~~~ Title/description/keywords/og ~~~~~~~~ */}
            {getSeoTags({
                page,
                seo
            })}

            {/* ~~~~~~~~ Custom tags ~~~~~~~~ */}
            <link rel="icon" href={'/static/favicon.ico'}/>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.4.1/css/swiper.min.css"/>

            {page === 'index' && (
                <link
                    rel="alternate"
                    hrefLang="x-default"
                    href="https://nicy.com.ua"
                />
            )}
            {/* ~~~~~~~~ Index/Follow ~~~~~~~~ */}
            {page !== 'search' && getMetaRobots({
                robotsContent,
                language,
                page
            })}

            {/* ~~~~~~~~ Canonical ~~~~~~~~ */}
            {page === 'search' ? (
                <SearchHead
                    locate={locate}
                    pathname={url}
                    markers={markers}
                    type={seo.type}
                    robots={{
                        robotsContent,
                        robotsParamsContent
                    }}/>
            ) : (
                <link rel="canonical" href={`${process.env.NEXT_PUBLIC_API_URL}${url?.split('?')[0]}`}/>
            )}
        </NextHead>
    );
};

const mapStateToProps = state => ({
    language: state.locate.language,
    country: state.locate.country,
    seo: state.seo,
    robotsContent: get(state.countryInfo, 'meta.robots_content'),
    robotsParamsContent: get(state.countryInfo, 'meta.robots_params_content'),
    filters: state.searchFilters.filters.filters
});

export default connect(
    mapStateToProps,
    null
)(Head);
