import './ServiceSelect.scss';
import * as React from 'react';
import {ajax} from '$/api/ajax';
import {SEARCH_SERVICES_BY_STATION} from '$/api/routes';
import {useLocale} from '~/hook/Locale.hook';
import AsyncSelect from 'react-select/async';
import {useTranslations} from '~/hook/Translations.hook';
import Form from 'antd/lib/form';

export function ServiceSelect({form: {getFieldDecorator, getFieldValue}, initialValue, required, placeholder, businessId, onLoad = () => null}) {

    const {
        language,
        currency
    } = useLocale();
    const t = useTranslations();
    const [isLoading, setIsLoading] = React.useState(false);
    const [services, setServices] = React.useState([]);
    const selectedMaster = getFieldValue('master');

    React.useEffect(() => {
        const fetchAndSetServices = async () => {
            const res = await fetchServices();
            setServices(res);
            onLoad();
        }
        fetchAndSetServices();
    }, [selectedMaster])


    const getPriceText = (price) => {
        return parseInt(price) === 0 ? t('order_form: fields: services: price_arrangement') : `${t('order_form: fields: services: price: from')} ${price} ${currency.symbol}`
    }

    const fetchServices = (text = '') => {
        return new Promise(((resolve, reject) => {

            ajax({
                url: `${SEARCH_SERVICES_BY_STATION}?station_id=${businessId}${text ? '&term=' + text : ''}${selectedMaster ? `&slot_id=${selectedMaster}` : ''}`,
                cb: res => {
                    resolve(res);
                },
                err: error => {
                    reject(error);
                },
                language: language === 'uk' ? 'UA' : language.toUpperCase()
            })
        }));
    }

    const customStyles = {
        dropdownIndicator: () => ({display: 'none'}),
        indicatorsContainer: () => ({display: 'none'}),
        control: (provider, state) => ({
            ...provider,
            minHeight: '50px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)',
            border: 'none',
            borderRadius: 0,
        }),
        placeholder: (provider, state) => ({
            ...provider,
            fontWeight: 600,
            color: '#bfbfbf'
        }),
        multiValue: (provider, state) => ({
            ...provider,
            backgroundColor: '#f7559b',
            height: '38px',
            fontSize: '16px',
            display: 'flex',
            padding: '0 10px',
            alignItems: 'center',
        }),
        multiValueLabel: (provider, state) => ({
            ...provider,
            color: 'white',
            fontSize: '16px'
        }),
        valueContainer: (provider, state) => ({
            ...provider,
            padding: '0 2.875rem',
        }),
        multiValueRemove: (provider, state) => ({
            ...provider,
            color: 'white',
            fontSize: '18px',
            cursor: 'pointer',
            '&:hover': {
                color: 'white',
                backgroundColor: '#f7559b',
            }
        }),
        menuList: (provider, state) => {
            return {
                ...provider,
                color: 'black',
                backgroundColor: '#f5f5f5',
                fontSize: '16px',
                pointer: 'cursor',
            }
        },
        option: (provided, state) => ({
            ...provided,
            color: state.isFocused ? 'white' : state.isSelected ? '#f7559b' : 'black',
            backgroundColor: state.isFocused ? '#f7559b' : state.isSelected ? 'white' : '#f5f5f5',
            '&:hover': {
                color: 'white',
                backgroundColor: '#f7559b'
            }
        }),
    }

    return (
        <div className="service-select">
            <Form.Item>
                {getFieldDecorator('service', {
                    rules: [
                        {
                            required: !!required,
                            message: t('form: error: required')
                        }
                    ],
                    initialValue: services.filter(service => initialValue.includes(parseInt(service.value)))
                })(
                    <AsyncSelect
                        className="service-select__select"
                        key={JSON.stringify(selectedMaster)}
                        closeMenuOnSelect={false}
                        loading={isLoading}
                        loadOptions={fetchServices}
                        noOptionsMessage={() => t('select: not_found')}
                        styles={customStyles}
                        placeholder={placeholder}
                        getOptionLabel={option => `${option.label} - ${getPriceText(option.price_from)}`}
                        isClearable={false}
                        hideSelectedOptions={false}
                        isMulti
                        defaultOptions
                        theme={theme => {
                            return {
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#f7559b',
                                    primary75: '#f7559b',
                                    primary: '#f7559b',
                                    primary50: '#f7559b'
                                },
                            }
                        }
                        }
                    />
                )}
            </Form.Item>
        </div>
    )
}
