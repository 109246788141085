import './FooterCopyright.scss';
import React from 'react';
import {useTranslations} from '~/hook/Translations.hook';
import visaImage from '#/visa.png';
import mcImage from '#/master-card.png';

export const FooterCopyright = () => {
    const t = useTranslations();
    const copyright = `© ${new Date().getFullYear()} ${t('footer: copyright')}`;

    return (
        <div className="footer-copyright">
            <span className="footer-copyright__date">{copyright}</span>
            <span className="footer-copyright__info">{t('information_index')}</span>
            <div>
                <img src={visaImage} width="50px"/>
                <img src={mcImage} width="50px"/>
            </div>
        </div>
    );
};
