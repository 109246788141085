import './FooterAdditionalInfo.scss';
import React from 'react';
import Icon from '~/components/Icon/Icon';
import {useLocale} from '~/hook/Locale.hook';
import PropTypes from 'prop-types';

const FooterAdditionalInfoItem = ({url, title, iconType}) => (
    <a href={url} className="footer-additional-info-item">
        {iconType && (
            <div className="footer-additional-info-item__icon">
                <Icon type={iconType}/>
            </div>
        )}
        {title && (
            <div className="footer-additional-info-item__title">
                {title}
            </div>
        )}
    </a>
);

export const FooterAdditionalInfo = ({termsAndConditionsData, privacyStatementData}) => {
    const {locatesUrl} = useLocale();
    const additionalInfoUrl = (slug) => `/${locatesUrl}/${slug}`;

    return (
        <div className="footer-additional-info">
            {(termsAndConditionsData?.slug && termsAndConditionsData?.title) && (
                <FooterAdditionalInfoItem
                    url={additionalInfoUrl(termsAndConditionsData.slug)}
                    title={termsAndConditionsData.title}
                    iconType="user"
                />
            )}
            {(privacyStatementData?.slug && privacyStatementData?.title) && (
                <FooterAdditionalInfoItem
                    url={additionalInfoUrl(privacyStatementData.slug)}
                    title={privacyStatementData.title}
                    iconType="lock"
                />
            )}
        </div>
    );
};

FooterAdditionalInfo.propTypes = {
    termsAndConditionsData: PropTypes.shape({
        type: PropTypes.string,
        slug: PropTypes.string,
    }),
    privacyStatementData: PropTypes.shape({
        type: PropTypes.string,
        slug: PropTypes.string,
    })
};

FooterAdditionalInfo.defaultProps = {
    termsAndConditionsData: null,
    privacyStatementData: null
};
