import './OrderStatus.scss';
import React from 'react';
import {classes} from '~/helpers/classes';

export function OrderStatus({type, text}) {

    const classNames = classes('order-status',
        ['order-status_new', getStatus(type) === 'new'],
        ['order-status_verified', getStatus(type) === 'verified'],
        ['order-status_ready', getStatus(type) === 'ready'],
        ['order-status_canceled', getStatus(type) === 'canceled'],
    )

    return (
        <div className={classNames}>
            <div className="order-status__circle"/>
            <div className="order-status__text">{text}</div>
        </div>
    )

}

function getStatus(status) {
    switch (status) {
        case 'new_order' || 'processed':
            return 'new';

        case 'verified' || 'in_work':
            return 'verified';

        case 'ready':
            return 'ready';

        case 'canceled' || 'rewrite':
            return 'canceled';

        default:
            return status;

    }
}