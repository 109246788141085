import keys from 'lodash/keys';
import cloneDeep from 'lodash/cloneDeep';
import {
  ADDITIONAL_SERVICES,
  BODY_TYPES,
  BRAND,
  CITY,
  DATE,
  DISTANCE,
  GEO_TYPE,
  LATITUDE,
  LONGITUDE,
  NETWORK,
  NORM_HOUR,
  NORTH_EAST_LAT,
  NORTH_EAST_LNG,
  OBJECT,
  PAGE_NUMBER,
  PRICE,
  PROBLEM,
  QUADRATURE,
  SERVICE,
  SORT_BY,
  SOUTH_WEST_LAT,
  SOUTH_WEST_LNG,
  STYLE,
  TIME
} from '../constants/searchParameters';

const slugsIndexes = {
  [CITY]: {
    key: 'city',
    type: 'string'
  },
  [SERVICE]: {
    key: 'services',
    type: 'array'
  },
  [BRAND]: {
    key: 'brand',
    type: 'string'
  },
  [OBJECT]: {
    key: 'brand',
    type: 'string'
  },
  [LATITUDE]: {
    key: 'lat',
    type: 'string'
  },
  [LONGITUDE]: {
    key: 'lng',
    type: 'string'
  },
  [GEO_TYPE]: {
    key: 'geo_types',
    type: 'string'
  },
  [PROBLEM]: {
    key: 'problems',
    type: 'array'
  },
  [DATE]: {
    key: 'date',
    type: 'string'
  },
  [TIME]: {
    key: 'time',
    type: 'string'
  },
  [DISTANCE]: {
    key: 'distance',
    type: 'string'
  },
  [ADDITIONAL_SERVICES]: {
    key: 'additional_services',
    type: 'array'
  },
  [BODY_TYPES]: {
    key: 'body_type',
    type: 'array'
  },
  [NETWORK]: {
    key: 'networked',
    type: 'string'
  },
  [NORM_HOUR]: {
    key: 'hourly_rate',
    type: 'array'
  },
  [SORT_BY]: {
    key: 'sort_by',
    type: 'string'
  },
  sbm: {
    key: 'search_by_map',
    type: 'string'
  },

  [NORTH_EAST_LAT]: {
    key: 'ne_lat',
    type: 'string'
  },
  [NORTH_EAST_LNG]: {
    key: 'ne_lng',
    type: 'string'
  },
  [SOUTH_WEST_LAT]: {
    key: 'sw_lat',
    type: 'string'
  },
  [SOUTH_WEST_LNG]: {
    key: 'sw_lng',
    type: 'string'
  },

  [PAGE_NUMBER]: {
    key: 'page',
    type: 'string'
  },

  // Hallway prefixes
  [STYLE]: {
    key: 'styles',
    type: 'array'
  },
  [QUADRATURE]: {
    key: 'quadrature',
    type: 'string'
  },
  [PRICE]: {
    key: 'price_range',
    type: 'array'
  }
};

const slugsForParse = {
  services: '',
  problems: '',
  brand: null,
  station_type: '',
  city: null,
  networked: 'default',
  // Hallway slug
  styles: ''
};

function parseSearchPath(url) {

  const arrOptions = (url || location.pathname).split('/')
      .slice(3); //[domen, locale, find];
  const options = {};
  const forParse = cloneDeep(slugsForParse);
  const slugsIndexesKeys = keys(slugsIndexes);

  arrOptions.forEach(option => {
    slugsIndexesKeys.forEach(slugIndex => {
      if (option.startsWith(slugIndex)) {
        const {key, type} = slugsIndexes[slugIndex];
        let value = option.replace(slugIndex, '');

        if (type === 'array') {
          value = value.split('_');
        }
        options[key] = value;
      }
    })
  })

  options.station_type = slugsIndexesKeys
      .every(slug => !arrOptions[0].startsWith(slug)) && arrOptions[0] !== 'abs'
      ? arrOptions[0].split('_')
      : '';

  for (const key in forParse) {
    if (options[key]) {
      forParse[key] = options[key].toString();
    }
  }

  return {
    options,
    forParse
  };
}

export default parseSearchPath;
