import './CommentTextarea.scss';
import React from 'react';
import {Form, Input} from 'antd';

const {TextArea} = Input;

export function CommentTextarea({form: {getFieldDecorator}, placeholder}) {

    return (
        <div className="comment-textarea">
            <Form.Item>
                {getFieldDecorator('comment')(
                    <TextArea
                        className="comment-textarea__textarea"
                        rows={4}
                        placeholder={placeholder}/>
                )}
            </Form.Item>
        </div>
    )
}