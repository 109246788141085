import './Checkbox.scss';
import React from 'react';
import PropTypes from 'prop-types';
import AntCheckbox from 'antd/lib/checkbox';
import {classes} from '~/helpers/classes';

const Checkbox = ({children, className, value, onChange, ...otherProps}) => {
    const classNames = classes('checkbox', className);

    return (
        <AntCheckbox
            className={classNames}
            value={value}
            onChange={onChange}
            {...otherProps}
        >
            {children}
        </AntCheckbox>
    );
};

Checkbox.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

Checkbox.defaultProps = {
    children: null,
    className: null,
    value: null,
    onChange: () => null
};

export default Checkbox;

