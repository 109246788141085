import './TabsLoginRegister.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Button from '~/components/buttons/Button/Button';
import {setModalLoginRegisterType} from '$/redux/loginRegister/actions';
import {useTranslations} from '~/hook/Translations.hook';

export function TabsLoginRegister({active}) {

    const t = useTranslations();

    const dispatch = useDispatch();
    const textLogin = t('auth: sign_in: title');
    const textRegister = t('auth: sign_up: title');
    const setType = type => dispatch(setModalLoginRegisterType(type));
    const btnLogin = (
        <Button type="dot-link" onClick={() => setType('login')}>
            {textLogin}
        </Button>
    );

    const btnRegister = (
        <Button type="dot-link" onClick={() => setType('register')}>
            {textRegister}
        </Button>
    );

    return (
        <div className="tabs-login-register">
            <span>{active === 'login' ? textLogin : btnLogin}</span>
            <span>{active === 'register' ? textRegister : btnRegister}</span>
        </div>
    );

}

TabsLoginRegister.propTypes = {
    active: PropTypes.oneOf(['login', 'register']).isRequired,
};
