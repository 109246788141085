import './HeaderNavigationMenu.scss';
import React from 'react';

export const HeaderNavigationMenu = ({navLinks}) => (
    <nav className='header-navigation-menu'>
        {navLinks && (
            navLinks.map((navItem, index) => (
                <a
                    className='header-navigation-menu__item'
                    key={index}
                    href={navItem.url}
                >
                    {navItem.title}
                </a>
            ))
        )}
    </nav>
);
