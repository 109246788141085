import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import withLocate from '~/hocs/withLocate';
import {logout} from '$/redux/auth/actions';

import './style.scss';

const {func} = PropTypes;

class Logout extends Component {
    static propTypes = {
        logoutDispatched: func
    };

    static defaultProps = {
        logoutDispatched: () => null
    };

    handleClick = () => {
        const {logoutDispatched} = this.props;
        logoutDispatched();
    };

    render() {
        const {t} = this.props;
        const logOut = t('header: signed_in_navbar: logout');

        return (
            <div className="Logout" onClick={this.handleClick}>
                <span>{logOut}</span>
            </div>
        );
    }
}

const dispatchedActions = {
    logoutDispatched: logout
};

export default connect(
    null,
    dispatchedActions
)(withLocate(Logout));
