import './FooterContacts.scss';
import React from 'react';

export const FooterContacts = ({phone, email}) => (
    <div className="footer-contacts">
        {phone && (
            <div
                className="footer-contacts__item"
                itemProp="contactPoints"
            >
                <a
                    className="footer-contacts__item-link"
                    href={`tel:${phone}`}
                    itemProp="telephone"
                >
                    {phone}
                </a>
            </div>
        )}
        {email && (
            <div
                className="footer-contacts__item"
                itemProp="contactPoints"
            >
                <a
                    className="footer-contacts__item-link"
                    href={`mailto:${email}`}
                    itemProp="email"
                >
                    {email}
                </a>
            </div>
        )}
    </div>
);
