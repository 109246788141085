import './FormForgotPassword.scss';
import React from 'react';
import {useDispatch} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Form from 'antd/lib/form';
import message from 'antd/lib/message';
import Button from '~/components/buttons/Button/Button';
import InputEmail from '../items/InputEmail/InputEmail';
import {passwordForgot} from '$/redux/auth/actions';
import {setModalLoginRegisterVisible} from '$/redux/loginRegister/actions';
import {useTranslations} from '~/hook/Translations.hook';

function FormForgotPassword({form}) {

    const t = useTranslations();
    const dispatch = useDispatch();

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                dispatch(passwordForgot({
                    data: {user: {...values}},
                    cb: () => dispatch(setModalLoginRegisterVisible(false)),
                    err: errors => {
                        for (let i = 0; i < errors.length; i++) {
                            message.error(errors[i].detail);
                        }
                    }
                }));
            }
        });
    };

    return (
        <Form
            onSubmit={handleSubmit}
            className="form-forgot-password"
        >
            <InputEmail
                form={form}
                required
            />
            <Button
                type="primary"
                htmlType="submit"
                disabled={isEmpty(form.getFieldValue('email'))}
            >
                {t('auth: recovery: button')}
            </Button>
        </Form>
    );
}

export default (Form.create()(FormForgotPassword));
