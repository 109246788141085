import React from 'react';
import moment from 'moment';
import flattenDeep from 'lodash/flattenDeep';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';

export const locateHOC = (WrappedComponent, currentProject) => props => {
    const {
        language,
        country,
        countryCode,
        languages,
        countries,
        translations,
        localeTranslations
    } = props;

    const isAllEmpty = isEmpty(countries)
        || isEmpty(languages);

    if (isAllEmpty) {
        return false;
    }

    const currency = countries.filter(i => i.country_code === countryCode)[0].currency;
    const translation = !isEmpty(translations) && !isEmpty(translations[language === 'ua' ? 'uk' : language])
        ? translations[language === 'ua' ? 'uk' : language]
        : {};
    const localeTranslation = !isEmpty(localeTranslations) && !isEmpty(localeTranslations[language]) ? localeTranslations[language] : {};
    const t = (query, options) => {
        const phrase = translation[query];

        if (options) {
            let updatePhrase = [phrase];

            keys(options).map(i => {
                const tmp = updatePhrase.map((j, k) => {
                    if (typeof j === 'string' && j.includes(i)) {
                        const d = j.split(`%{${i}}`);
                        return typeof options[i] === 'string' ? Array.of(d[0], options[i], d[1]) : [d[0],
                            <React.Fragment key={k}>{options[i]}</React.Fragment>, d[1]];
                    }
                    return j;
                });

                updatePhrase = flattenDeep(tmp);
            });

            return updatePhrase;
        }

        return phrase || query;
    };
    const tl = (query, options) => {
        const phraseLocal = localeTranslation[query];

        if (options) {
            let updateLocatePhrase = [phraseLocal];

            keys(options).map(i => {
                const tmp = updateLocatePhrase.map((j, k) => {
                    if (typeof j === 'string' && j.includes(i)) {
                        const d = j.split(`%{${i}}`);
                        return [d[0], <React.Fragment key={k}>{options[i]}</React.Fragment>, d[1]];
                    }
                    return j;
                });

                updateLocatePhrase = flattenDeep(tmp);
            });

            return updateLocatePhrase;
        }

        return phraseLocal || query;
    };
    const d = iso => {
        return iso && moment(iso).format('LL');
    };
    const text = text => <span>{text}</span>;
    const locateFunctions = {t, d, tl, text};
    const locateOptions = {language, country, countryCode, currency};

    return <WrappedComponent {...locateFunctions} {...locateOptions} {...props} />;
};
