import './Collapse.scss';
import * as React from 'react';
import {classes} from '~/helpers/classes';
import PropTypes from 'prop-types';

export const Collapse = ({children, isOpen, transitionMs, ...commonProps}) => {
    const [initHeight, setInitHeight] = React.useState(0);
    const contentRef = React.createRef();

    React.useLayoutEffect(() => {
         setInitHeight(isOpen ? contentRef.current.offsetHeight : 0)
    }, [isOpen, contentRef]);

    const classNames = classes('collapse', commonProps.className);

    return (
        <div
            className={classNames}
            style={{
                height: `${initHeight}px`,
                transition: `height ${transitionMs}ms ease-out`,
            }}
        >
            <div className='collapse__content' ref={contentRef}>
                {children}
            </div>
        </div>
    );
};

Collapse.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    transitionMs: PropTypes.number,
};

Collapse.defaultProps = {
    children: null,
    isOpen: true,
    transitionMs: 300,
};
