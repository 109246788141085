import './FooterSocial.scss'
import React from 'react';
import Icon from '~/components/Icon/Icon';
import PropTypes from 'prop-types';

export const FooterSocial = ({title, socialResource}) => (
    <div className="footer-social">
        {socialResource && (
            <>
                <h5 className="footer-social__title">
                    {title}
                </h5>
                <div className="footer-social__icons">
                    {socialResource.map((icon, index) => (
                        <a
                            key={index}
                            className="footer-social__icon-item "
                            href={icon.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {icon.name && <Icon type={icon.name}/>}
                        </a>
                    ))}
                </div>
            </>
        )}
    </div>
);

FooterSocial.propTypes = {
    title: PropTypes.string,
    socialResource: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            name: PropTypes.string,
        })
    )
};

FooterSocial.defaultProps = {
    title: '',
    socialResource: null
};
