import * as React from 'react';
import debounce from 'lodash/debounce';

export function useWindowSize() {
  const [size, setSize] = React.useState([0, 0]);

  React.useLayoutEffect(() => {
    const updateSize = debounce(() => setSize([window.innerWidth, window.innerHeight]), 200);
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}
