import './CallbackSelect.scss';
import React from 'react';
import {Form, Select} from 'antd';
import {useTranslations} from '~/hook/Translations.hook';

const {Option} = Select;

export function CallbackSelect({
                                   initialValue,
                                   form: {getFieldDecorator}
                               }) {

    const t = useTranslations();

    return (
        <div className="callback-select">
            <Form.Item>
                {getFieldDecorator('callback', {initialValue: initialValue})(
                    <Select defaultValue="tomorrow">
                        <Option value="tomorrow">{t('station_card: form: call_back: tomorrow')}</Option>
                        <Option value="now">{t('station_card: form: call_back: now')}</Option>
                    </Select>
                )}
            </Form.Item>
        </div>
    );
}
