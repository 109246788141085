import React from 'react';
import {useDispatch} from 'react-redux';
import Button from '../Button/Button';
import {setModalLoginRegisterType} from '$/redux/loginRegister/actions';
import {useTranslations} from '~/hook/Translations.hook';

export function ButtonForgotPassword() {
    const t = useTranslations();
    const dispatch = useDispatch();

    return (
        <Button
            type="link"
            onClick={() => dispatch(setModalLoginRegisterType('forgot'))}
            style={{marginTop: 10}}
        >
            {t('auth: recovery: title')}
        </Button>
    );
}
