import './FooterMenu.scss'
import React from 'react';
import PropTypes from 'prop-types';
import {useLocale} from '~/hook/Locale.hook';

export const FooterMenu = ({menu}) => {
    const {locatesUrl} = useLocale();

    return (
        <div className="footer-menu">
            {menu && (
                menu.map((menuItem) => (
                    <a
                        className="footer-menu__item"
                        key={menuItem.path}
                        href={menuItem.type === 'relative' ? `/${locatesUrl}${menuItem.path}` : menuItem.path}
                    >
                        {menuItem.title}
                    </a>
                ))
            )}
        </div>
    );
};

FooterMenu.propTypes = {
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            title: PropTypes.string,
            path: PropTypes.string,
        })
    ).isRequired
};
