import './ButtonGoogle.scss';
import React from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';
import message from 'antd/lib/message';
import {setModalLoginRegisterVisible} from '$/redux/loginRegister/actions';
import {authGoogle} from '$/redux/auth/actions';
import Button from '../Button/Button';
import {useTranslations} from '~/hook/Translations.hook';
import {classes} from '~/helpers/classes';

const CLIENT_ID_GOOGLE = process.env.NEXT_PUBLIC_CLIENT_ID_GOOGLE;

export function ButtonGoogle({disabled, type}) {

    const t = useTranslations();
    const dispatch = useDispatch();

    const onSuccess = response => {
        dispatch(authGoogle({
            data: {access_token: response.accessToken}
        }));
        dispatch(setModalLoginRegisterVisible(false));
    };
    const text = type === 'register' ? t('auth: sign_up: google') : t('auth: sign_in: google');

    return (
        <GoogleLogin
            clientId={CLIENT_ID_GOOGLE}
            className="button-google-login"
            render={renderProps => (
                <Button
                    icon="google"
                    onClick={renderProps.onClick}
                    className={classes(
                        'button-google-login__icon',
                        ['button-google-login__icon_active', !disabled]
                    )}
                    disabled={type === 'register' ? disabled : false}
                >
                    {text}
                </Button>
            )}
            onSuccess={onSuccess}
            onFailure={res => (process.env.NODE_ENV !== 'development' && message.error(res.details))}
        />
    );
}

ButtonGoogle.propTypes = {
    type: PropTypes.oneOf(['login', 'register']).isRequired,
    disabled: PropTypes.bool
};

ButtonGoogle.defaultProps = {
    disabled: false
}
