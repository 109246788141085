import './Container.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {classes} from "~/helpers/classes";

function Container(
    {
        children,
        className,
        isFluid,
        innerRef,
        ...otherProps
    }
) {
    const classNames = classes(
        'container',
        ['container_fluid', isFluid],
        className
    );

    return (
        <div className={classNames} ref={innerRef} {...otherProps}>
            {children}
        </div>
    );
}

Container.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    full: PropTypes.bool,
    innerRef: PropTypes.func,
    otherProps: PropTypes.object
};

export default Container;
