import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input} from 'antd';
import {useTranslations} from '~/hook/Translations.hook';

function InputName({form: {getFieldDecorator}, initialValue, required}) {
    const t = useTranslations();

    return (
        <div className="input-name">
            <Form.Item>
                {getFieldDecorator('first-name', {
                    rules: [{
                        required: !!required,
                        message: t('form: error: required')
                    }],
                    initialValue: initialValue
                })(
                    <Input placeholder={t('station_card: form: name: placeholder')}/>
                )}
            </Form.Item>
        </div>
    );
}

InputName.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired
    }).isRequired,
    required: PropTypes.bool,
    initialValue: PropTypes.string
};

InputName.defaultProps = {
    required: false,
    initialValue: null
}

export default InputName;
