import './Auth.scss';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AccountUser} from '../AccountUser/AccountUser';
import {useTranslations} from '~/hook/Translations.hook';
import {setModalLoginRegisterType, setModalLoginRegisterVisible} from '$/redux/loginRegister/actions';

function Auth() {
    const dispatch = useDispatch();
    const t = useTranslations();
    const {user, authorized} = useSelector(state => ({
        user: state.auth.user,
        authorized: state.auth.authorized
    }));

    const openModal = (type) => {
        dispatch(setModalLoginRegisterVisible(true));
        dispatch(setModalLoginRegisterType(type));
    };

    return (
        <div className="auth">
            {authorized ? (
                <AccountUser {...user} />
            ) : (
                <div className="auth__login-and-register">
                    <div className="auth__login-and-register-button" onClick={() => openModal('login')}>
                        {t('devise: sessions: new: sign_in')}
                    </div>
                    <div className="auth__login-and-register-separator">/</div>
                    <div className="auth__login-and-register-button" onClick={() => openModal('register')}>
                        {t('devise: registrations: new: sign_up')}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Auth;
