import React, {memo} from 'react';
import Router from 'next/router';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cn from 'classnames';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';

import './SelectLocate.scss';
import {setCountry, setLanguage} from '$/redux/locate/actions';
import {clearAllTabs, clearAllTerms, clearCarModels} from '$/redux/searchFields/actions';
import {getCountryInfo} from '$/redux/countryInfo/actions';
import withLocate from '~/hocs/withLocate';
import {getHomeInfo} from '$/redux/homeInfo/actions';
import {setPopularServices} from '$/redux/common/actions';
import Icon from '~/components/Icon/Icon';
import AbFlag from '#/ab-flag.svg';
import parseSearchPath from '$/helpers/parseSearchPath';
import {parseLink} from '$/redux/search/actions';
import {findTranslation} from '~/helpers/getAlternateLink';
import {BRAND, CITY, PROBLEM, SERVICE, TYPE} from '$/constants/searchParameters';
import {SEARCH_PAGE} from '~/constants/urlTemplates';
import {changeUrl, updateParams} from '~/helpers/url/utils';

const {oneOf, string, func} = PropTypes;

SelectLocate.propTypes = {
    type: oneOf(['language', 'country']).isRequired,
    t: func,
    language: string,
    country: string,
    setLanguage: func,
    setCountry: func,
    getCountryInfo: func,
    getHomeInfo: func
};

function SelectLocate(props) {
    const {
        setLanguage,
        setCountry,
        type,
        language,
        country,
        t,
        countries,
        languages,
        windowWidth
    } = props;
    const moreThenTablet = windowWidth === 'tablet' || windowWidth === 'laptop' || windowWidth === 'desktop';
    const placement = moreThenTablet ? 'bottomLeft' : 'bottomCenter';
    const isLang = type === 'language';
    const value = isLang ? language === 'uk' ? 'ua' : language : country;
    const text = (isLang ? t('locale') : t('country')) + ': ';
    const sortCountries = [
        ...countries.filter(i => i.country_code !== 'SG'),
        ...countries.filter(i => i.country_code === 'SG')
    ];

    const getFlagImage = (code) => {
        switch (code) {
            case 'de':
                return <img
                    className="country-flag"
                    src="https://cdn.autobooking.com/assets/flags/4x3/de-e7323083675d25107c85f0cea6534bd21d21e77e6d524d92e1b2d0a4e21117a1.svg"
                    alt={code}/>;
            case 'nl':
                return <img
                    className="country-flag"
                    src="https://cdn.autobooking.com/assets/flags/4x3/nl-4f0ecea529dd4c0099dd41a7801fa5ac44aa04cd872ca31e11efd58b5a813774.svg"
                    alt={code}/>;
            case 'pl':
                return <img
                    className="country-flag"
                    src="https://cdn.autobooking.com/assets/flags/4x3/pl-f55a710991a94c2e6c2f9f1a7a4a36ebb84b24b7c9d9df4e1ffe86d61321fa6f.svg"
                    alt={code}/>;
            case 'ua':
                return <img
                    className="country-flag"
                    src="https://cdn.autobooking.com/assets/flags/4x3/ua-7f7db03c3b492aaacd11b0abac70d543a7c0ec5265a36d529bbb80a7c432d81a.svg"
                    alt={code}/>;
            default:
                return <AbFlag className="country-flag"/>;
        }
    };

    const countriesOption = sortCountries.map(i => ({
        id: i.country_code.toLowerCase(),
        label: (
            <div className="option">
                {
                    i.country_code === 'SG'
                        ? <AbFlag className="country-flag"/>
                        : (
                            getFlagImage(i.country_code.toLowerCase())
                        )
                }
                <span className="country-name">
          {t(`countries: ${i.country_code}`)}
        </span>
            </div>
        )
    }));
    const languagesOption = languages.map(i => ({
        id: i.id,
        label: i.id.charAt(0)
            .toUpperCase() + i.id.substr(1)
    }));
    const option = isLang ? languagesOption : countriesOption;


    const redirect = async ({key: value}) => {
        let {language, country, getCountryInfo, getHomeInfo, clearAllTabs, parsedUrlParams, setPopularServices, clearAllTerms} = props;

        const prevLanguage = language;

        if (type === 'language') {
            language = value;
        } else {
            country = value;
        }

        language = languages.find(i => i.id === language).id;
        const params = window.location.pathname.split('/');
        const result = `/${language}-${country}${params.length < 3 ? '' : `/${params.slice(2)
            .join('/')}`}`;

        localStorage.setItem('language', language);
        localStorage.setItem('country', country);
        if (Router.router.route === '/search') {
            const {
                forParse: parsedData
            } = parseSearchPath();

            const {
                city: parsedCity,
                services: parsedServices,
                problems: parsedProblems,
                brand: parsedBrand,
                station_type: parsedType
            } = await parseLink(parsedData, prevLanguage);

            const services = parsedServices
                .map(({translations}) => findTranslation(translations, language))
                .filter(translation => translation)
                .join('_');
            const problems = parsedProblems
                .map(({translations}) => findTranslation(translations, language))
                .filter(translation => translation)
                .join('_');
            const types = parsedType
                .map(({translations}) => findTranslation(translations, language))
                .filter(translation => translation)
                .join('_');
            const brand = findTranslation(parsedBrand.translations, language);
            const citySlug = findTranslation(parsedCity.translations, language);

            const params = [
                {
                    prefix: SERVICE,
                    value: services
                },
                {
                    prefix: BRAND,
                    value: brand
                },
                {
                    prefix: CITY,
                    value: citySlug
                },
                {
                    prefix: PROBLEM,
                    value: problems
                },
                {
                    prefix: TYPE,
                    value: types
                }
            ];
            const {separator, template} = SEARCH_PAGE;
            changeUrl({
                separator,
                template,
                withoutReload: false,
                params: updateParams(parsedUrlParams, params),
                locale: `${language}-${country}`
            });
        } else {
            if (type === 'language') {
                value === 'ua' ? setLanguage('uk') : setLanguage(value);
                language = value;
            } else {
                const countryCode = value.toUpperCase();

                setCountry({
                    country: value,
                    countryCode
                });
                country = value;
            }

            window.history.pushState(null, null, result);

            clearCarModels();

            setPopularServices({
                isOpen: false,
                isActive: null
            });

            if (props.country !== country || props.language !== language) {
                getCountryInfo(country);
                getHomeInfo({
                    country,
                    language
                });
            }
        }
    };

    const getSelected = value => {
        const {locate: {language, country}, type} = props;

        if (type === 'language') {
            if (value.toLowerCase() === (language === 'uk' ? 'ua' : language)) return 'selected';
        } else if (type === 'country') {
            const countryCode = value.props.children[0].props.code;

            if (country === (countryCode !== undefined && countryCode.toLowerCase())
                || (country === 'sg' && countryCode === undefined)
            ) {
                return 'selected';
            }
        }
    };

    const menu = () => (
        <Menu onClick={redirect}>
            <Menu.Item className="title" disabled key={text}>
                {text}
            </Menu.Item>

            {option.map(i => (
                <Menu.Item className={getSelected(i.label)} key={i.id}>
                    {i.label}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <div className={cn('SelectLocate', type)}>
            <Dropdown
                placement={placement}
                overlayClassName="SelectLocate-dropdown"
                overlay={menu}
                trigger={['click']}
            >
                <div>
                    {option.find(i => i.id === value).label}
                    <Icon type="select-arrow"/>
                </div>
            </Dropdown>
        </div>
    );
}


const mapStateToProps = state => ({
    windowWidth: state.adaptive.windowWidth,
    parsedUrlParams: state.search.parsedUrlParams,
    locate: state.locate
});

const dispatchedActions = {
    setLanguage,
    setCountry,
    getCountryInfo,
    getHomeInfo,
    clearAllTabs,
    setPopularServices,
    clearAllTerms,
    clearCarModels
};

export default connect(mapStateToProps, dispatchedActions)(withLocate(memo(SelectLocate)));
