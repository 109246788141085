import './Footer.scss';
import React from 'react';
import {useSelector} from 'react-redux';
import {useTranslations} from '~/hook/Translations.hook';
import {Col, Row} from 'antd';
import Container from '~/components/Container/Container';
import {FooterMenu} from './components/FooterMenu';
import {FooterCopyright} from './components/FooterCopyright';
import {FooterSocial} from './components/FooterSocial';
import {FooterContacts} from './components/FooterContacts';
import {FooterAdditionalInfo} from './components/FooterAdditionalInfo';

export const Footer = () => {
    const t = useTranslations();
    const {
        contacts,
        privacyStatementData,
        termsAndConditionsData,
        socialResource,
        menu
    } = useSelector(state => ({
        contacts: state.countryInfo.info.contacts,
        privacyStatementData: state.countryInfo.info.privacy_statement,
        termsAndConditionsData: state.countryInfo.info.terms_and_conditions_for_station,
        socialResource: state.countryInfo.info.social,
        menu: state.countryInfo.info.footer_menu
    }));

    return (
        <footer className="footer" itemScope itemProp="https://schema.org/LocalBusiness">
            <div className="footer__content">
                <Container>
                    <div className="footer__content-inner">
                        <Row type="flex">
                            <Col xs={24} sm={24} md={8}>
                                <div className="footer__content-item">
                                    <FooterContacts
                                        phone={contacts.hot_line}
                                        email={contacts.email_user}
                                    />
                                    <FooterAdditionalInfo
                                        termsAndConditionsData={termsAndConditionsData}
                                        privacyStatementData={privacyStatementData}
                                    />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                                <div className="footer__content-item">
                                    {menu && <FooterMenu menu={menu}/>}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                                <div className="footer__content-item">
                                    <FooterSocial
                                        title={t('we_in_social_networks')}
                                        socialResource={socialResource}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <div className="footer__bottom">
                <Container>
                    <FooterCopyright/>
                </Container>
            </div>
        </footer>
    );
};
