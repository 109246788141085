export const NEIGHBORHOOD = 'neighborhood';
export const LOCALITY = 'locality';
export const STATE_AREA = 'aal1';
export const COUNTRY = 'country';
export const POLITICIAN = 'political';
export const STATE_AREA_BY_GEOCODE = 'administrative_area_level_1';











