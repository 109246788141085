import React from 'react';
import Item from 'antd/lib/form/FormItem'
import {Input} from 'antd';
import {useTranslations} from '~/hook/Translations.hook';

export function InputPassword({form: {getFieldDecorator}}) {
    const t = useTranslations();

    return (
        <div className="input-password">
            <Item>
                {getFieldDecorator('password', {
                    rules: [
                        {
                            required: true,
                            message: t('form: error: required')
                        },
                        {
                            min: 8,
                            message: t('form: error: password')
                        },
                    ],
                })(
                    <Input
                        type="password"
                        placeholder={t('auth: fields: password')}
                    />
                )}
            </Item>
        </div>
    );
}
