import './HeaderLayout.scss';
import React from 'react';
import {classes} from '~/helpers/classes';

export const HeaderLayout = (
    {
        children,
        backgroundImageUrl,
        className,
        isSticky = false
    }) => {
    const headedStyle = {
        'background-image': `url(${backgroundImageUrl})`,
        'background-repeat': 'no-repeat',
        'backgroundSize': `cover`
    };
    const classNames = classes(
        'header-layout',
        className,
        ['header-layout_sticky', isSticky]
    );

    return (
        <header className={classNames} style={backgroundImageUrl && headedStyle}>
            {children}
        </header>
    )
};
