import './HeaderNavigation.scss';
import React from 'react';
import {useSelector} from 'react-redux';
import Auth from '~/components/Auth/Auth';
import SelectLocate from '~/components/SelectLocate/SelectLocate';
import {useLocale} from '~/hook/Locale.hook';
import {BurgerIcon} from '~/components/BurgerIcon/BurgerIcon';
import {Collapse} from '~/components/Collapse/Collapse';
import {HeaderNavigationLogo} from './HeaderNavigationLogo';
import {HeaderNavigationMenu} from './HeaderNavigationMenu';
import {useWindowSize} from '~/hook/WindowSize.hook';
import {WIDTH_DESKTOP} from '$/constants/constants';

export function HeaderNavigation() {
    const {locatesUrl} = useLocale();
    const [width] = useWindowSize();
    const {
        headerLinks,
        phone
    } = useSelector(state => ({
        headerLinks: state.countryInfo.info.header_menu,
        phone: state.countryInfo.info.contacts.hot_line,
    }));
    const navMenuLinks = headerLinks.map(item => ({
        title: item.title,
        url: item.type === 'relative' ? `/${locatesUrl}${item.path}` : item.path
    }));
    const [isOpened, setIsOpened] = React.useState(false);

    const RenderContent = () => (
        <>
            {phone && (
                <div className="header-navigation__phone" itemProp="contactPoint">
                    <a
                        className="header-navigation__phone-link"
                        itemProp="telephone"
                        href={`tel:${phone}`}
                    >
                        {phone}
                    </a>
                </div>
            )}
            <div className="header-navigation__menu">
                <HeaderNavigationMenu navLinks={navMenuLinks}/>
            </div>
            <div className="header-navigation__registration">
                <Auth/>
            </div>
        </>
    );

    return (
        <div className="header-navigation" itemScope itemProp="https://schema.org/Organization">
            <div className="header-navigation__wrapper">
                <div className="header-navigation__logo">
                    <HeaderNavigationLogo logoUrl={`/${locatesUrl}`}/>
                </div>
                {width > WIDTH_DESKTOP ? (
                    <>
                        <RenderContent/>
                        <div className="header-navigation__lang">
                            <SelectLocate type="language"/>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="header-navigation__lang">
                            <SelectLocate type="language"/>
                        </div>
                        <div className="header-navigation__burger">
                            <BurgerIcon
                                isActive={isOpened}
                                onClick={() => setIsOpened(!isOpened)}
                            />
                        </div>
                    </>
                )}
            </div>
            { width < WIDTH_DESKTOP && (
                <Collapse isOpen={isOpened}>
                    <div className="header-navigation__collapse">
                        <RenderContent/>
                    </div>
                </Collapse>
            )}
        </div>
    );
}
