import './BrandSelect.scss';
import * as React from 'react';
import {ajax} from '$/api/ajax';
import {SEARCH_BRANDS_BY_STATION} from '$/api/routes';
import {useLocale} from '~/hook/Locale.hook';
import groupBy from 'lodash/groupBy';
import {useTranslations} from '~/hook/Translations.hook';
import Form from 'antd/lib/form';
import {Select} from 'antd';
import debounce from 'lodash/debounce'
import Spinner from '~/components/Spinner/Spinner';

const {Option, OptGroup} = Select;

export function BrandSelect({form: {getFieldDecorator}, initialValue, required, placeholder, businessId}) {

    const {language} = useLocale();
    const t = useTranslations();
    const [isLoading, setIsLoading] = React.useState(false);
    const [brands, setBrands] = React.useState([]);

    React.useEffect(() => {
        fetchBrands();
    }, [language]);

    const fetchBrands = async (text = '') => {
        setIsLoading(true);
        const data = await new Promise(((resolve, reject) => {
            ajax({
                url: `${SEARCH_BRANDS_BY_STATION}?station_id=${businessId}${text ? '&term=' + text : ''}`,
                cb: res => {
                    resolve(res);
                },
                err: error => {
                    reject(error);
                },
                language: language === 'uk' ? 'UA' : language.toUpperCase()
            })
        }));
        const groupData = groupBy(data, it => it.category_code);
        setBrands(groupData);
        setIsLoading(false);
    }

    return (
        <div className="brand-select">
            <Form.Item>
                {getFieldDecorator('brand', {
                    rules: [
                        {
                            required: !!required,
                            message: t('form: error: required')
                        }
                    ],
                    initialValue: initialValue
                })(
                    <Select
                        className="brand-select__select"
                        placeholder={placeholder}
                        onSearch={debounce(fetchBrands, 800)}
                        onFocus={fetchBrands}
                        notFoundContent={isLoading
                            ? <Spinner size="small" color={'rose'}/>
                            : <div>{t('select: not_found')}</div>
                        }
                        optionFilterProp="children"
                        showSearch
                        allowClear
                    >
                        {brands['owned'] && (
                            <OptGroup label={brands['owned'][0].category_name}>
                                {brands['owned'].map((it, index) => (
                                        <Option
                                            key={index}
                                            value={it.brand_id}
                                        >
                                            {it.label}
                                        </Option>
                                    )
                                )}
                            </OptGroup>
                        )}
                        {brands['all'] && (<OptGroup label={brands['all'][0].category_name}>
                                {brands['all']?.map((it, index) => {
                                        return <Option
                                            key={index}
                                            value={it.brand_id}
                                        >
                                            {it.label}
                                        </Option>
                                    }
                                )}
                            </OptGroup>
                        )}
                    </Select>
                )}
            </Form.Item>
        </div>
    )
}
