import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import ConfigProvider from 'antd/lib/config-provider';
import moment from 'moment';
import isTouchDevice from 'is-touch-device';
import {ModalLoginRegister} from '~/components/modals/ModalLoginRegister/ModalLoginRegister';
import debounce from 'lodash/debounce';
import {setWidth} from '$/redux/adaptive/actions';
import {classes} from '~/helpers/classes';
import Router from 'next/router';
import {ModalFormOrder} from '~/components/modals/ModalFormOrder/ModalFormOrder';
import ruRu from 'antd/lib/locale/ru_RU';
import enGb from 'antd/lib/locale/en_GB';
import deDe from 'antd/lib/locale/de_DE';
import plPl from 'antd/lib/locale/pl_PL';
import trTr from 'antd/lib/locale/tr_TR';
import ukUa from 'antd/lib/locale/uk_UA';
import {ajax} from '$/api/ajax';
import {URL_GET_USER} from '$/api/routes';
import {setUser} from '$/redux/auth/actions';
import isEmpty from 'lodash/isEmpty';

Page.propTypes = {
    children: PropTypes.node.isRequired
};

function Page({children}) {
    const {language, country, authorized} = useSelector((state) => ({
        language: state.locate.language,
        country: state.locate.country,
        authorized: state.auth.authorized
    }));
    const dispatch = useDispatch();

    React.useLayoutEffect(() => {
        const updateWidth = debounce(() => (
            dispatch(setWidth(window.innerWidth))
        ), 400);
        window.addEventListener('resize', updateWidth);
        updateWidth();

        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    React.useEffect(() => {
        if (language) {
            const currentLocale = language === 'ua' ? 'uk' : language;
            if (currentLocale !== moment.locale()) {
                moment.locale(currentLocale)
            }
        }
    }, [language, moment.locale()]);

    React.useEffect(() => {
        if (!authorized) {
            getUser();
        }
    }, [authorized])

    const getUser = async () => {
        const {data} = await new Promise(((resolve, reject) => {
            ajax({
                url: URL_GET_USER,
                cb: res => {
                    resolve(res);
                },
                err: error => {
                    reject(error);
                },
                language: language === 'uk' ? 'UA' : language.toUpperCase()
            })
        }));
        if (!isEmpty(data)) {
            dispatch(setUser(data));
        }
    }

    const classNames = classes('Layout', ['not-touch-device', !isTouchDevice()]);
    const routeWithFormOrder = ['/search', '/project', '/business'];

    const getLocale = localeItem => {
        switch (localeItem) {
            case 'en':
                return enGb;
            case 'uk':
                return ukUa;
            case 'ua':
                return ukUa;
            case 'ru':
                return ruRu;
            case 'tr':
                return trTr;
            case 'nl':
                return enGb;
            case 'de':
                return deDe;
            case 'pl':
                return plPl;
            default:
                return enGb;
        }
    };

    return (country && children) && (
        <div className={classNames}>
            <ModalLoginRegister/>
            {routeWithFormOrder.includes(Router?.router?.route) && <ModalFormOrder/>}
            <ConfigProvider locale={getLocale(language)}>
                {children}
            </ConfigProvider>
        </div>
    );
}

export default Page;
