import './ModalFormOrder.scss'
import React from 'react'
import Modal from '~/components/modals/Modal/Modal';
import {useDispatch, useSelector} from 'react-redux';
import {FormOrder} from '~/components/form/FormOrder/FormOrder';
import {closeModal} from '$/redux/order/actions';
import {ajax} from '$/api/ajax';
import {FETCH_STATION_ORDER_SETTINGS} from '$/api/routes';
import {useLocale} from '~/hook/Locale.hook';
import Spinner from '~/components/Spinner/Spinner';

export function ModalFormOrder() {

    const {open, businessId, serviceId, masterId} = useSelector(state => ({
        open: state.order.open,
        businessId: state.order.data.businessId,
        masterId: state.order.data.masterId,
        serviceId: state.order.data.serviceId
    }));
    const dispatch = useDispatch();
    const {language} = useLocale();
    const [isLoading, setIsLoading] = React.useState(false);
    const [settings, setSettings] = React.useState([]);

    React.useEffect(() => {
        if (businessId) {
            fetchSetting();
        }
    }, [businessId]);

    const fetchSetting = async () => {
        setIsLoading(true);
        const {data} = await new Promise(((resolve, reject) => {
            ajax({
                url: `${FETCH_STATION_ORDER_SETTINGS(businessId)}`,
                cb: res => {
                    resolve(res);
                },
                err: error => {
                    reject(error);
                },
                language: language === 'uk' ? 'UA' : language.toUpperCase()
            })
        }));
        setSettings(data);
        setIsLoading(false);
    }

    return (
        <Modal
            className="modal-form-order"
            visible={open}
            onCancel={() => dispatch(closeModal())}
        >
            {isLoading || !settings ? <Spinner color={'rose'}/> : (
                <FormOrder
                    businessId={businessId}
                    serviceId={serviceId}
                    masterId={masterId}
                    settings={settings}
                />
            )
            }
        </Modal>
    )
}
