import './ModalCheckYourEmail.scss';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '~/components/buttons/Button/Button';
import {setModalLoginRegisterType} from '$/redux/loginRegister/actions';
import {useTranslations} from '~/hook/Translations.hook';

export function ModalCheckYourEmail() {
    const {email} = useSelector(state => ({
        email: state.auth.registerUser.email
    }));
    const t = useTranslations();
    const dispatch = useDispatch();

    return (
        <div className="modal-check-your-email">
            <span className="modal-check-your-email__title">{t('auth: email_check: title')}</span>
            <p className="modal-check-your-email__description">
                {t('auth: email_check: desc', {
                    email: <span className="modal-check-your-email__email">{email}</span>
                })}
            </p>

            <div className="modal-check-your-email__back-to">
                {t('auth: email_check: back', {
                    sign_up: (
                        <Button type="link" onClick={() => dispatch(setModalLoginRegisterType('register'))}>
                            {t('auth: sign_up: button')}
                        </Button>
                    )
                })}
            </div>
        </div>
    );
}
