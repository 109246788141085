export const BRAND = 'm-';
export const OBJECT = 'o-';
export const STYLE = 'st-';
export const QUADRATURE = 'q-';
export const PRICE = 'pr-';
export const CITY = 'c-';
export const SERVICE = 's-';
export const PROBLEM = 'p-';
export const DATE = 'rd-';
export const TIME = 'rt-';
export const ADDITIONAL_SERVICES = 'as-';
export const BODY_TYPES = 'bt-';
export const NETWORK = 'n-';
export const NORM_HOUR = 'hr-';
export const SORT_BY = 'sb-';
export const SEARCH_BY_MAP = 'sbm';
export const LATITUDE = 'lat-';
export const LONGITUDE = 'lng-';
export const PAGE_NUMBER = 'pn-';
export const TYPE = '';
export const DISTANCE = 'd-';
export const GEO_TYPE = 'gt-';
export const NORTH_EAST_LAT = 'ne_lat-';
export const NORTH_EAST_LNG = 'ne_lng-';
export const SOUTH_WEST_LAT = 'sw_lat-';
export const SOUTH_WEST_LNG = 'sw_lng-';

export const ALL_PREFIX = [BRAND, STYLE, QUADRATURE, PRICE, OBJECT, CITY, SERVICE, PROBLEM, DATE, TIME, ADDITIONAL_SERVICES, BODY_TYPES, NETWORK, NORM_HOUR,
    SORT_BY, SEARCH_BY_MAP, LATITUDE, LONGITUDE, PAGE_NUMBER, TYPE, DISTANCE, GEO_TYPE, NORTH_EAST_LAT, NORTH_EAST_LNG, SOUTH_WEST_LAT, SOUTH_WEST_LNG];
