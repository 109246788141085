import './InputPhone.scss';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import Form from 'antd/lib/form';
import {useTranslations} from '~/hook/Translations.hook';
import {useLocale} from '~/hook/Locale.hook';

function InputPhone({form: {getFieldDecorator}, required, initialValue}) {

    const t = useTranslations();
    const {country} = useLocale();

    return (
        <div className="input-phone">
            <Form.Item>
                {getFieldDecorator('phone', {
                    rules: [
                        {
                            required: !!required,
                            message: t('form: error: required')
                        },
                        {
                            max: 13,
                            message: t('form: error: phone')
                        },
                        {
                            min: 9,
                            message: t('form: error: phone')
                        }
                    ],
                    initialValue: initialValue
                })(
                    <PhoneInput
                        preferredCountries={['us', 'de', 'pl', 'ua', 'nl']}
                        country={country}
                        buttonClass="input-phone__dropdown"
                        inputClass="input-phone__input"
                    />
                )}
            </Form.Item>
        </div>
    );
}

export default InputPhone;


