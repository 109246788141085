import './ModalLogin.scss';
import React from 'react';
import Form from 'antd/lib/form';
import {TabsLoginRegister} from '../TabsLoginRegister/TabsLoginRegister';
import {ButtonFacebook} from '~/components/buttons/ButtonFacebook/ButtonFacebook';
import {ButtonGoogle} from '~/components/buttons/ButtonGoogle/ButtonGoogle';
import {FormLogin} from '~/components/form/FormLogin/FormLogin';
import {ButtonForgotPassword} from '~/components/buttons/ButtonForgotPassword/ButtonForgotPassword';
import {ButtonRegister} from '~/components/buttons/ButtonRegister/ButtonRegister';
import {useTranslations} from '~/hook/Translations.hook';

function ModalLogin({form}) {
    const t = useTranslations();

    return (
        <div className="modal-login">
            <TabsLoginRegister active="login"/>
            <p className="modal-login__description">{t('auth: sign_in: one_click')}</p>
            <div className="modal-login__social-buttons">
                <ButtonFacebook type="login"/>
                <ButtonGoogle type="login"/>
            </div>
            <p className="modal-login__description">{t('auth: or_with')}</p>
            <FormLogin form={form}/>
            <ButtonForgotPassword/>
            <div className="modal-login__register-button">
                {t('auth: sign_in: desc', {
                    sign_up: <ButtonRegister/>
                })}
            </div>
        </div>
    );
}

export default (Form.create()(ModalLogin));
