import {Router} from '../../../server/routes';
import {parseUrlToArray} from '$/helpers/url/parsers';
import {SEARCH_PAGE_SEPARATOR} from '~/constants/urlTemplates';
import {setParsedUrlParams} from '$/redux/search/actions';

const hasAdditionalParams = (params, template, separator) => {
    if (!separator) return false;

    const separatorIndex = template.findIndex(prefix => prefix === separator);
    const additionalFromTemplate = template.filter((prefix, index) => index > separatorIndex);

    return params.some(({prefix}) => additionalFromTemplate.includes(prefix));
};

const generateLink = (_params, template, separator) => {
    const params = _params.filter(({value}) => Boolean(value));
    const isExpanded = hasAdditionalParams(params, template, separator);

    return template
        .filter(
            (_prefix) => _prefix === separator || params.some(({prefix}) => prefix === _prefix)
        )
        .map(
            (_prefix) => {
                const {value} = params
                    .find(({prefix, value}) => _prefix === prefix && Boolean(value))
                || (isExpanded && _prefix === separator && ({value: separator}))
                || {value: ''};

                return value
                    ? `${_prefix}${value === separator || value === '.' ? '' : value}`
                    : '';
            }
        )
        .join('/');
};

export const changeUrl = async (options) => {
    const {
        template: _template = [],
        params = [],
        handler = () => _template,
        separator = '',
        withoutReload = true,
        locale
    } = options;

    const {
        query: {
            locale: currentLocale,
            ...slugs
        }
    } = Router;
    const template = handler(_template);
    const link = generateLink(params, template, separator);
    const url = `/${locale || currentLocale}/find/${link}`;

    if (withoutReload) {
        await window.history.pushState({}, null, url);
        const data = parseUrlToArray(slugs, SEARCH_PAGE_SEPARATOR);
        await setParsedUrlParams(data);
    } else {
        window.location = url;
    }

};

export const updateParams = (oldParams, newParams) => [
    ...oldParams.filter(
        ({prefix}) => !newParams.some(param => param.prefix === prefix)),
    ...newParams
];
