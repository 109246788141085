import * as React from 'react';
import {useSelector} from 'react-redux';

export const useLocale = () => {
    const {language, country, countryCode, countries} = useSelector(store => ({
        language: store.locate.language,
        country: store.locate.country,
        countryCode: store.locate.countryCode,
        countries: store.locate.countries
    }));

    const currency = React.useMemo( ()=> (
        countries.find(country => country.country_code === countryCode)?.currency
    ), [countryCode]);
    const getLanguageCode = language => language === 'uk' ? 'ua' : language;
    const locatesUrl = `${getLanguageCode(language)}-${country}`;

    return {
        language,
        country,
        countryCode,
        currency,
        locatesUrl,
        getLanguageCode
    };
};
