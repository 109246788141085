import isEmpty from 'lodash/isEmpty';

export const findTranslation = (translations = [], language) => {
    const translation = translations && translations.find(({locale}) => locale === language);

    return translation ? translation.slug : '';
};

export const getAlternateLink = (
    {stationType, city, services, problems, brand} = {},
    language
) => {
    const stationTypeSlug = !isEmpty(stationType)
        && stationType.map(({translations}) => findTranslation(translations, language));
    const citySlug = !isEmpty(city) && `c-${findTranslation(city.translations, language)}`;
    const servicesSlug = !isEmpty(services)
        && `s-${services.map(({translations}) => findTranslation(translations, language)).join('_')}`;
    const brandSlug = !isEmpty(brand) && `m-${findTranslation(brand.translations, language)}`;
    const problemsSlug = !isEmpty(problems)
        && `p-${problems.map(({translations}) => findTranslation(translations, language)).join('_')}`;

    return [stationTypeSlug, citySlug, servicesSlug, brandSlug, problemsSlug].reduce(
        (string, slug) => (isEmpty(slug) ? string : string.concat(`/${slug}`)),
        ''
    );
};
