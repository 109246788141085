import React from 'react';
import isEmpty from 'lodash/isEmpty';
import getSearchURLComponents from '$/helpers/url/getSearchURLComponents';

const getCanonicalLink = (urlWithoutLocate, markers, services, hasSeparator, markerSlugs) => {
  let link = urlWithoutLocate;

  if (hasSeparator) {
    link = link.split('/abs/')[0];
  }

  if (services.length > 1) {
    link = link.replace(services.join('_'), services[0]);
  }

  if (markerSlugs.length === 1) {
    const stationSlug = markerSlugs[0];

    if ((markers.find(({slug}) => slug === stationSlug) || {}).id === 2) {
      const newServices = services.length
          ? services.join('')
              .concat(`_${stationSlug}`)
          : `/s-${stationSlug}`;

      link = link.replace(`${stationSlug}/`, '')
          .concat(newServices);
    }
  }

  return link;
};

const getMetaRobots = ({locate, type, robotsContent, robotsParamsContent, hasSeparator}) => {
  const notIndexPage = ['brand', 'city'];
  if (locate.startsWith('en') || notIndexPage.includes(type)) {
    return <meta name="robots" content="noindex, nofollow"/>;
  }

  if (robotsContent && !isEmpty(robotsContent)) {
    return <meta name="robots" content={robotsContent}/>;
  }

  if (hasSeparator && robotsParamsContent) {
    return <meta name="robots" content={robotsParamsContent}/>;
  }
  return <></>;
};

const SearchHead = ({locate, pathname = '', markers, type, robots: {robotsContent, robotsParamsContent}}) => {
  const regex = new RegExp(`/${locate}/`, 'g');
  const urlWithoutLocate = pathname.replace(regex, '');
  const {hasSeparator, services = [], markers: markerSlugs = []} = getSearchURLComponents(
      locate,
      pathname
  );

  return (
      <>
        {/* ~~~~~~~~ Index/Follow ~~~~~~~~ */}
        {getMetaRobots({
          locate,
          type,
          robotsContent,
          robotsParamsContent,
          hasSeparator
        })}
        <link
            rel="canonical"
            href={`${process.env.NEXT_PUBLIC_API_URL}/${locate}/${getCanonicalLink(
                urlWithoutLocate,
                markers,
                services,
                hasSeparator,
                markerSlugs
            )}`}
        />
      </>
  );
};

export default SearchHead;
