import './DateTimePicker.scss';
import React from 'react';
import {useLocale} from '~/hook/Locale.hook';
import {useTranslations} from '~/hook/Translations.hook';
import {ajax} from '$/api/ajax';
import {FETCH_WORKING_HOURS} from '$/api/routes';
import keys from 'lodash/keys';
import uniq from 'lodash/uniq';
import range from 'lodash/range';
import without from 'lodash/without';
import groupBy from 'lodash/groupBy';
import {Col, DatePicker, Form, Row, TimePicker} from 'antd';
import moment from 'moment';

export function DateTimePicker({form: {getFieldDecorator, getFieldValue}, dateInitialValue, timeInitialValue, required, placeholder, businessId, serviceIds, disabled}) {

    const {language} = useLocale();
    const t = useTranslations();
    const [workDay, setWorkDay] = React.useState([]);
    const [workHours, setWorkHours] = React.useState([]);

    React.useEffect(() => {
        async function fetchWorkHours() {
            const {data} = await new Promise(((resolve, reject) => {
                ajax({
                    url: `${FETCH_WORKING_HOURS(businessId)}?${serviceIds?.length ? '&service_ids=' + serviceIds.join(',') : ''}`,
                    cb: res => {
                        resolve(res);
                    },
                    err: error => {
                        reject(error);
                    },
                    language: language === 'uk' ? 'UA' : language.toUpperCase()
                })
            }));
            const days = keys(data);
            setWorkDay(days);
            setWorkHours(data);
        }

        fetchWorkHours();
    }, [serviceIds]);

    const isSetInitialValue = () => {
        return workHours && workHours[dateInitialValue?.format('YYYY-MM-DD')]
    }

    return (
        <div className="date-time-picker">
            <Row type="flex" justify="space-between" gutter={20}>
                <Col xs={24} md={!getFieldValue('date') ? 24 : 12}>
                    <Form.Item>
                        {getFieldDecorator('date', {
                            rules: [
                                {
                                    required: !!required,
                                    message: t('form: error: required')
                                }
                            ],
                            initialValue: isSetInitialValue() ? dateInitialValue : undefined
                        })(
                            <DatePicker
                                placeholder={placeholder}
                                disabled={!workDay || disabled}
                                disabledDate={it => {
                                    return !workDay.includes(moment(it)
                                    .format('YYYY-MM-DD'))
                                }}
                                format="LL"
                                className="date-time-picker__date-picker"
                                dropdownClassName="date-time-picker__dropdown"
                                showToday={false}
                            />
                        )}
                    </Form.Item>
                </Col>
                {getFieldValue('date') && (<Col xs={24} md={12}>
                    <Form.Item>
                        {getFieldDecorator('time', {
                            rules: [
                                {
                                    required: !!required,
                                    message: t('form: error: required')
                                }
                            ],
                            initialValue: isSetInitialValue() ? timeInitialValue : undefined
                        })(
                            <TimePicker
                                placeholder={placeholder}
                                disabled={!workHours || disabled || !getFieldValue('date')}
                                format="HH:mm"
                                className="date-time-picker__time-picker"
                                dropdownClassName="date-time-picker__dropdown"
                                minuteStep={30}
                                hideDisabledOptions
                                disabledHours={() => (
                                    without(
                                        range(0, 24),
                                        ...uniq(
                                            workHours[getFieldValue('date')
                                            .format('YYYY-MM-DD')]
                                            .map(time => {
                                                const [hours] = time.split(':');
                                                return parseInt(hours);
                                            })
                                        )
                                    )
                                )}
                                disabledMinutes={e => {
                                    if (!e) {
                                        return range(0, 60, 30);
                                    }
                                    return without(
                                        range(0, 60, 30),
                                        ...groupBy(
                                            workHours[getFieldValue('date')
                                            .format('YYYY-MM-DD')],
                                            date => parseInt(date.split(':')[0])
                                        )[e]
                                        .map(time => {
                                            const [hour, minute] = time.split(':');
                                            return parseInt(minute);
                                        })
                                    )
                                }}
                            />
                        )}
                    </Form.Item>
                </Col>)
                }
            </Row>
        </div>
    )
}
