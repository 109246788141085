import './ModalForgotYourPassword.scss'
import React from 'react';
import {useDispatch} from 'react-redux';
import {setModalLoginRegisterType} from '$/redux/loginRegister/actions';
import Button from '~/components/buttons/Button/Button';
import FormForgotPassword from '~/components/form/FormForgotPassword/FormForgotPassword';
import {useTranslations} from '~/hook/Translations.hook';

export function ModalForgotYourPassword() {
    const t = useTranslations();
    const dispatch = useDispatch();

    return (
        <div className="modal-forgot-your-password">
            <span className="modal-forgot-your-password__title">
                {t('auth: recovery: title')}
            </span>
            <p className="modal-forgot-your-password__description">
                {t('auth: recovery: desc')}
            </p>
            <FormForgotPassword/>
            <div className="modal-forgot-your-password__back-to">
                {t('auth: sign_in: desc', {
                    sign_up: (
                        <Button type="link" onClick={() => dispatch(setModalLoginRegisterType('register'))}>
                            {t('auth: sign_up: button')}
                        </Button>
                    )
                })}
            </div>
        </div>
    );
}
