import {BRAND, CITY, PROBLEM, SERVICE} from '../../constants/searchParameters';

const separator = '/abs/';
const categories = {
  [CITY]: 'city',
  [SERVICE]: 'services',
  [PROBLEM]: 'problems',
  [BRAND]: 'brand'
};
const URLComponents = {};

const getSearchURLComponents = (locate, pathname) => {
  URLComponents.hasSeparator = pathname.includes(separator);

  const URLParts = pathname
      .slice(1)
      .replace(locate, '')
      .split(separator)[0]
      .split('/') || [];

  URLParts.forEach(urlPart => {
    const urlPartCategory = Object.keys(categories).find(category => urlPart.startsWith(category));

    URLComponents[urlPartCategory ? categories[urlPartCategory] : 'markers'] = urlPart.split('_');
  });

  return URLComponents;
};

export default getSearchURLComponents;
