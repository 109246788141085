import './ButtonFacebook.scss';
import React from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import message from 'antd/lib/message';
import {setModalLoginRegisterVisible} from '$/redux/loginRegister/actions';
import {authFacebook} from '$/redux/auth/actions';
import Button from '../Button/Button';
import {useTranslations} from '~/hook/Translations.hook';
import {classes} from '~/helpers/classes';

const CLIENT_ID_FACEBOOK = process.env.NEXT_PUBLIC_CLIENT_ID_FACEBOOK;

export function ButtonFacebook({disabled, type}) {

    const t = useTranslations();
    const dispatch = useDispatch();
    const facebookCallback = response => {
        dispatch(authFacebook({
            data: {access_token: response.accessToken}
        }));
        dispatch(setModalLoginRegisterVisible(false));
    };

    const text = type === 'register' ? t('auth: sign_up: facebook') : t('auth: sign_in: facebook');

    return (
        <FacebookLogin
            appId={CLIENT_ID_FACEBOOK}
            className="button-facebook-login"
            render={renderProps => (
                <Button
                    icon="facebook"
                    onClick={renderProps.onClick}
                    className={classes(
                        'button-facebook-login__icon',
                        ['button-facebook-login__icon_active', !disabled]
                    )}
                    disabled={type === 'register' ? disabled : false}
                >
                    {text}
                </Button>
            )}
            callback={facebookCallback}
            onFailure={res => (process.env.NODE_ENV !== 'development' && message.error(res.details))}
        />
    );
}

ButtonFacebook.propTypes = {
    type: PropTypes.oneOf(['login', 'register']).isRequired,
    disabled: PropTypes.bool
};

ButtonFacebook.defaultProps = {
    disabled: false
}
